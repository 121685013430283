export class Parceiro {
    id: string;
    document: string;
    firstName: string;
    middleName: string;
    lastName: string;
    name: string;
    type: string;

    typeDescription: string;
    fullName: string;

    constructor(values: { id: string, document: string, firstName: string, middleName: string, lastName: string, name: string, type: string }) {

        this.id = values.id;
        this.document = values.document;
        this.firstName = values.firstName;
        this.middleName = values.middleName;
        this.lastName = values.lastName;
        this.name = values.name;
        this.type = values.type;

        if (this.middleName) {
            this.fullName = this.firstName + " " + this.middleName + " " + this.lastName;
        }
        else {
            this.fullName = this.firstName + " " + this.lastName;
        }

        if (!this.type)
            this.type = '';

        switch (this.type.toString()) {
            case '1':
                this.typeDescription = 'Investidor';
                break;
            case '2':
                this.typeDescription = 'Correspondente';
                break;
            case '3':
                this.typeDescription = 'Investidor Correspondente';
                break;
            case '4':
                this.typeDescription = 'Responsável';
                break;
            default:
                this.typeDescription = 'Não Definido';
                break;
        };
    }
}