export class TickerSymbol {
  id: string;
  code: string;
  price: string;
  priceDate: string;
  updateDate: string;
  employeeLogId: string;
  employee: string;

  constructor(values: { id: string, code: string, price: string, priceDate: string, updateDate: string, employeeLogId: string, employee: string;}) {

      this.id  = values.id;
      this.code = values.code;
      this.price = values.price;
      this.priceDate = values.priceDate;
      this.updateDate = values.updateDate;
      this.employeeLogId = values.employeeLogId;
      this.employee = values.employee;
  }
}
