import { Injectable } from '@angular/core';
import { ContatoCliente } from "../shared/contato-cliente";
import { Papel } from "../shared/papel";
import { Negocio } from '../shared/negocio';
import { NegocioLista } from '../shared/negocio-lista';
import { ApiService } from './api.service';
import { ApiMockService } from './api-mock.service';
import { Observable } from 'rxjs/Observable';
import { NegocioResult } from '../shared/negocio-result';
import { FuncionariosCliente } from '../shared/funcionarios-cliente';
import { Funcionario } from '../shared/funcionario';
import { Acionista } from '../shared/acionista';
import { AcionistaResult } from '../shared/acionista-result';
import { Apontamento } from '../shared/apontamento';
import { Parceiro } from '../shared/parceiro';
import { ParceiroResult } from '../shared/parceiro-result';
import { ApontamentoResult } from '../shared/apontamento-result';
import { NegocioRealizado } from '../shared/negocio-realizado';
import { NegocioRealizadoResult } from '../shared/negocio-realizado-result';
import { NegocioRealizadoLista } from '../shared/negocio-realizado-lista';
import { DefaultResult } from '../shared/default-result';
import { Permissao } from '../shared/permissao';
import { DefaultData } from '../shared/default-data';
import { PagamentoColaborador } from '../shared/pagamentocolaborador';
import { PagamentoColaboradorResult } from '../shared/pagamentocolaborador-result';
import { PopupEmployee } from '../shared/popup-employee';
import { CreateUser } from '../shared/createUser';
import { User } from '../shared/user';
import { AccessScreen } from '../shared/AccessScreen';
import { AccessMenu } from '../shared/AccessMenu';
import { AccessPermissions } from '../shared/AccessPermissions';
import { AccessPermissionsPerfil } from '../shared/AccessPermissionsPerfil';
import { deal } from '../shared/deal';
import { UpdateDealEmployee } from '../shared/update-deal-employee';
import { ClientHistoryResult } from '../shared/ClientHistoryResult';
import { TickerSymbol } from '../shared/TickerSymbol';
import { TickerSymbolHistory } from '../shared/TickerSymbolHistory';
import { ProcessoTickerSymbol } from '../shared/ProcessoTickerSymbol';
import { TickerSymbolHistoryDate } from '../shared/TickerSymbolHistory-date';
import { ReportClientRequest } from '../shared/ReportClientRequest';
import { ReportClientResult } from '../shared/ReportClientResult';
import { StatusNegocio } from '../shared/statusNegocio';
import { LogCountResult } from '../shared/logCountResult';
import { MensagemCommandResult } from '../shared/mensagemResult';
import { LogUserListRequest } from '../shared/logUserListRequest';
import { ClientImport } from '../shared/clientImport';
import { GetImportReportCommand } from '../shared/GetImportReportCommand';
import { MensagemListCommandResult } from '../shared/mensagemListResult';
import { LogUserResult } from '../shared/logUserResult';
import { DealDataCustomerCommandRequest } from '../shared/DealDataCustomerCommandRequest';
import { ResetSenhaSistemaCommand } from '../shared/ResetSenhaSistemaCommand';
import { GetImportCpfCommandRequest } from '../shared/GetImportCpfCommandRequest';
import { EmployeeCommandResult } from '../shared/EmployeeCommandResult';


@Injectable({
  providedIn: 'root'
})
export class NegocioDataService {

  constructor(private api: ApiService, private apiMock: ApiMockService) { }

  //#region Deal
  addDeal(deal: Negocio): Observable<NegocioResult> {
    return this.api.createDeal(deal);
  }

  getPermission(authorized: string): Observable<Permissao> {
    return this.api.getPermission(authorized);
  }

  addPermission(transferir: Boolean, somenteLeitura: Boolean, authorized: string, employees: string[], permitted: string, expiryDate: Date): Observable<DefaultResult> {
    return this.api.addPermission(transferir, somenteLeitura, authorized, employees, permitted, expiryDate);
  }

  deleteDealById(dealId: string): Observable<Negocio> {
    return this.api.deleteDealById(dealId);
  }

  updateDeal(deal: Negocio): Observable<NegocioResult> {
    return this.api.updateDeal(deal);
  }

  getAllDeals(dtRetIni: Date, dtRetFim: Date, excluirBase: string): Observable<NegocioLista[]> {
    return this.api.getAllDeals(dtRetIni, dtRetFim, excluirBase);
  }

  getDealsBySearch(search: string, searchDoc: string, searchCity: string, dtNegIni: Date, dtNegFim: Date, dtContIni: Date, dtContFim: Date, dtIni: Date, dtFim: Date, sit: string, cliSit: string, compartilhado: string, employee: string, employeeLegal: string, tpNeg: string, fechado: string, finalizado: string, judicial: string, comJuridico: string, enviadoBanco: string, cadastro: string, localizado: string, contatado: string, vendeuResgatou: string, temInteresse: string, ExcluirBase: string, selectRealtor: string): Observable<NegocioLista[]> {
    return this.api.getDealsBySearch(search, searchDoc, searchCity, dtNegIni, dtNegFim, dtContIni, dtContFim, dtIni, dtFim, sit, cliSit, compartilhado, employee, employeeLegal, tpNeg, fechado, finalizado, judicial, comJuridico, enviadoBanco, cadastro, localizado, contatado, vendeuResgatou, temInteresse, ExcluirBase, selectRealtor);
  }

  getDealById(dealId: string): Observable<Negocio> {
    return this.api.getDealById(dealId);
  }

  getContactOrderById(dealId: string): Observable<ContatoCliente> {
    return this.api.getContactOrderById(dealId);
  }

  getContactClient(): Observable<MensagemCommandResult> {
    return this.api.getContactClient();
  }

  CreateUser(docFunc: CreateUser): Observable<CreateUser>{
    return this.api.CreateUser(docFunc);
  }

  updatePassword(Id: String, Password: String): Observable<DefaultResult>{
    return this.api.updatePassword(Id, Password);
  }

  GetPopupEmployee(employeeId: string): Observable<MensagemCommandResult> {
    return this.api.GetPopupEmployee(employeeId);
  }

  getDealByDocument(employee: string, document: string): Observable<Negocio> {
    return this.api.getDealByDocument(employee, document);
  }
  //#endregion

  //#region DoneDeal
  addDoneDeal(deal: NegocioRealizado): Observable<NegocioRealizadoResult> {
    return this.api.createDoneDeal(deal);
  }

  updateDoneDeal(deal: NegocioRealizado): Observable<NegocioRealizadoResult> {
    return this.api.updateDoneDeal(deal);
  }

  getAllDoneDeals(): Observable<NegocioRealizadoLista[]> {
    return this.api.getAllDoneDeals();
  }

  getDoneDealsBySearch(search: string, searchDoc: string, dtDoneIni: Date, dtDoneFim: Date, employee: string): Observable<NegocioRealizadoLista[]> {
    return this.api.getDoneDealsBySearch(search, searchDoc, dtDoneIni, dtDoneFim, employee);
  }

  getDoneDealById(dealId: string): Observable<NegocioRealizado> {
    return this.api.getDoneDealById(dealId);
  }
  //#endregion

  //#region Corresponding
  saveCorresponding(partner: Parceiro): Observable<ParceiroResult> {
    return this.api.saveCorresponding(partner);
  }

  getCorrespondingByDocument(documento: string): Observable<Parceiro> {
    return this.api.getCorrespondingByDocument(documento);
  }

  public getAllCorrespondings(): Observable<Parceiro[]> {
    return this.api.getAllCorresponding();
  }
  //#endregion

  saveNote(note: Apontamento): Observable<ApontamentoResult> {
    return this.api.saveNote(note);
  }

  //#region Auxiliares

  public getAllCompanies(): Observable<DefaultData[]> {
    return this.api.getAllCompanies();
  }

  public getAllUnits(): Observable<DefaultData[]> {
    return this.api.getAllUnits();
  }

  public getAllFixExpenses(): Observable<DefaultData[]> {
    return this.api.getAllFixExpenses();
  }

  public getAllVariableExpenses(): Observable<DefaultData[]> {
    return this.api.getAllVariableExpenses();
  }


  saveCompany(data: DefaultData): Observable<DefaultResult> {
    return this.api.saveCompany(data);
  }

  saveUnit(data: DefaultData): Observable<DefaultResult> {
    return this.api.saveUnit(data);
  }

  saveFixExpense(data: DefaultData): Observable<DefaultResult> {
    return this.api.saveFixExpense(data);
  }

  saveVariableExpense(data: DefaultData): Observable<DefaultResult> {
    return this.api.saveVariableExpense(data);
  }

  //#endregion

  //#region Shareholders
  public getAllShareholders(): Observable<Acionista[]> {
    return this.api.getAllShareholders();
  }

  public GetAllUser(): Observable<User[]> {
    return this.api.GetAllUser();
  }

  public getShareholder(shareholderId: string): Observable<Acionista> {
    return this.api.getShareholder(shareholderId);
  }

  public getShareholderByDocument(documento: string): Observable<Acionista> {
    return this.api.getShareholderByDocument(documento);
  }

  saveShareholder(shareholder: Acionista): Observable<AcionistaResult> {
    return this.api.saveShareholder(shareholder);
  }

  deleteShareholder(shareholderId: string): Observable<AcionistaResult> {
    return this.api.deleteShareholder(shareholderId);
  }
  //#endregion

  //#region EmployeePayments
  public getAllEmployeePayments(): Observable<PagamentoColaborador[]> {
    return this.api.getAllEmployeePayments();
  }
  //#endregion


  //#region Expenses
  getEmployeePaymentsBySearch(dtIni: Date, dtFim: Date, company: string, unit: string, employee: string): Observable<PagamentoColaborador[]> {
    return this.api.getEmployeePaymentsBySearch(dtIni, dtFim, company, unit, employee);
  }

  public getEmployeePayment(employeePaymentId: string): Observable<PagamentoColaborador> {
    return this.api.getEmployeePayment(employeePaymentId);
  }

  saveEmployeePayment(employeePayment: PagamentoColaborador): Observable<PagamentoColaboradorResult> {
    return this.api.saveEmployeePayment(employeePayment);
  }

  deleteEmployeePayment(employeePaymentId: string): Observable<PagamentoColaboradorResult> {
    return this.api.deleteEmployeePayment(employeePaymentId);
  }
  //#endregion

  //#region outros
  getAllContatosCliente(): Observable<ContatoCliente[]> {
    return this.apiMock.getAllContatosCliente();
  }

  getAllPapeis(): Observable<Papel[]> {
    return this.api.getAllPapeis();
  }

  getAllCorretores(): Observable<Funcionario[]> {
    return this.api.getAllCorretores();
  }

  getAllInvestidores(): Observable<Parceiro[]> {
    return this.api.getAllInvestidores();
  }

  getAllResponsaveis(): Observable<Parceiro[]> {
    return this.api.getAllResponsaveis();
  }

  clienteExiste(document: string): Observable<FuncionariosCliente> {
    return this.api.clienteExiste(document);
  }

  isMasterUser(): boolean {
    return this.api.isMasterUser();
  }

  isAdminUser(): boolean {
    return this.api.isAdminUser();
  }

  getEmployee(): string {
    return this.api.getEmployee();
  }

  getEmployeeBirthDate(): string {
    return this.api.getEmployeeBirthDate();
  }

  getEmployeeName(): string {
    return this.api.getEmployeeName();
  }

  isClientManagerUser(): boolean {
    return this.api.isClientManagerUser();
  }

  getPermissaoUnica(code: number): boolean {
    return this.api.getPermissaoUnica(code);
   }

  getTipoUsuario(): string {
    return this.api.getTipoUsuario();
  }

  public getResetSenha(): boolean {
    return this.api.getResetSenha();
  }

  getLog(dtLog: string): Observable<Blob> {
    return this.api.getLog(dtLog);
  }

  public GetAccessScreen(Menu: string): Observable<AccessScreen[]> {
    return this.api.GetAccessScreen(Menu);
  }

  public GetAccessScreenProfile(Menu: string, userProfile: string): Observable<AccessScreen[]> {
    return this.api.GetAccessScreenProfile(Menu, userProfile);
  }

  public GetAccessMenu(): Observable<AccessMenu[]> {
    return this.api.GetAccessMenu();
  }

  public GetListAccessPermissions(IdEmployee: string): Observable<MensagemListCommandResult> {
    return this.api.GetListAccessPermissions(IdEmployee);
  }

  public GetAccessPermissions(IdEmployee: string, nomeMenu: string, nomeScreen: string): Observable<MensagemCommandResult> {
    return this.api.GetAccessPermissions(IdEmployee, nomeMenu, nomeScreen);
  }

  public GetPerfilUsuario(IdEmployee: string): Observable<AccessPermissionsPerfil> {
    return this.api.GetPerfilUsuario(IdEmployee);
  }

  public createListEmployeeAccess(listaAccess: AccessPermissions[]): Observable<AccessPermissions> {
    return this.api.createListEmployeeAccess(listaAccess);
  }

  public createEmployeeAccess(itemAccess: AccessPermissions): Observable<AccessPermissions> {
    return this.api.createEmployeeAccess(itemAccess);
  }

  public CreateEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {
    return this.api.CreateEmployeeAccessPerfil(itemAccess);
  }

  public UpdateEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {
    return this.api.UpdateEmployeeAccessPerfil(itemAccess);
  }

  public ValidaEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {
    return this.api.ValidaEmployeeAccessPerfil(itemAccess);
  }

  public deleteEmployeeAccess(itemAccess: AccessPermissions): Observable<AccessPermissions> {
    return this.api.deleteEmployeeAccess(itemAccess);
  }

  public GetDealsIds(id: string): Observable<deal> {
    return this.api.GetDealsIds(id);
  }

  public GetAccessEmployeeUser(idEmployee: string): Observable<User> {
    return this.api.GetAccessEmployeeUser(idEmployee);
  }

  public getValidaSenha(senha: string): Observable<User> {
    return this.api.getValidaSenha(senha);
  }

  public getAtualizarSenha(user: User): Observable<User> {
    return this.api.getAtualizarSenha(user);
  }

  updateDealEmployee(dealEmployee: UpdateDealEmployee): Observable<MensagemCommandResult> {
    return this.api.updateDealEmployee(dealEmployee);
  }

  public createClienteHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {
    return this.api.createClienteHistory(clienteHistory);
  }

  public GetClientHistory(dealId: string): Observable<ClientHistoryResult[]>{
    return this.api.GetClientHistory(dealId);
  }

  public UpdateClientHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {
    return this.api.UpdateClientHistory(clienteHistory);
  }
  public DeleteClientHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult>{
    return this.api.DeleteClientHistory(clienteHistory);
  }

  public requestLastDateUpdate(): Observable<Date> {
    return this.api.requestLastDateUpdate();
  }

  public carregaTickerSymbol(): Observable<TickerSymbol[]> {
    return this.api.carregaTickerSymbol();
  }

  public carregaTickerSymbolHistory(tickerSymbolHistoryDate: TickerSymbolHistoryDate): Observable<TickerSymbolHistory[]> {
    return this.api.carregaTickerSymbolHistory(tickerSymbolHistoryDate);
  }

  public ExecutarTickerSymbol(idEmployee: string): Observable<ProcessoTickerSymbol> {
    return this.api.ExecutarTickerSymbol(idEmployee);
  }

  public getRequestSenha(userName: string): Observable<User>{
    return this.api.getRequestSenha(userName);
  }

  public GetReportClient(reportClient: ReportClientRequest): Observable<MensagemCommandResult> {
    return this.api.GetReportClient(reportClient);
  }

  public atualizaStatusNegocio(statusNegocio: StatusNegocio): Observable<MensagemCommandResult> {
    return this.api.atualizaStatusNegocio(statusNegocio);
  }

  public logCount(logUserRequest: LogUserListRequest): Observable<LogCountResult[]> {
    return this.api.logCount(logUserRequest);
  }

  public permissaoDeletarCliente(dealId: string): Observable<MensagemCommandResult> {
    return this.api.permissaoDeletarCliente(dealId);
  }

  public DeleteCliente(dealId: string): Observable<MensagemCommandResult> {
    return this.api.DeleteCliente(dealId);
  }

  getAllEmplyeeType(type: string): Observable<Funcionario[]> {
    return this.api.getAllEmplyeeType(type);
  }

  public createClienteHistoryTemp(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {
    return this.api.createClienteHistoryTemp(clienteHistory);
  }

  public UpdateClientHistoryTemp(idClientTemp: number, dealId: string): Observable<ClientHistoryResult> {
    return this.api.UpdateClientHistoryTemp(idClientTemp, dealId);
  }

  public GetClientHistoryTemp(clientIdTemp: number): Observable<ClientHistoryResult[]>{
    return this.api.GetClientHistoryTemp(clientIdTemp);
  }

  public DeleteLogOnline(employeeId: string): Observable<MensagemCommandResult> {
    return this.api.DeleteLogOnline(employeeId);
  }

  public GetUserOnline(): Observable<MensagemListCommandResult> {
    return this.api.GetUserOnline();
  }

  public PermissaoEmployee(code: number): Observable<boolean> {
    return this.api.PermissaoEmployee(code);
  }

  public GetListPermission(): Observable<MensagemListCommandResult> {
    return this.api.GetListPermission();
  }

  public GetListPermissionEmployee(code: number): Observable<MensagemListCommandResult> {
    return this.api.GetListPermissionEmployee(code);
  }

  public CreatePermissionEmployee(code: number, employeeId: string): Observable<MensagemCommandResult> {
    return this.api.CreatePermissionEmployee(code, employeeId);
  }

  public DeletePermissionEmployee(code: number, employeeId: string): Observable<MensagemCommandResult> {
    return this.api.DeletePermissionEmployee(code, employeeId);
  }

  public CreateLog(dealId: string): Observable<MensagemCommandResult>{
    return this.api.CreateLog(dealId);
  }

  public createUserLogOnline(): Observable<MensagemCommandResult>{
    return this.api.createUserLogOnline();
  }

  public GetListEmployeeType(): Observable<MensagemCommandResult>{
    return this.api.GetListEmployeeType();
  }

  public ImportReport(report: GetImportReportCommand[]): Observable<MensagemCommandResult>{
    return this.api.ImportReport(report);
  }

  public GetImportReport(): Observable<MensagemListCommandResult>{
    return this.api.GetImportReport();
  }

  public GetClientImport(cpf: string): Observable<MensagemCommandResult>{
    return this.api.GetClientImport(cpf);
  }

  public GetClientDuplicate(cpf: string): Observable<MensagemCommandResult>{
    return this.api.GetClientDuplicate(cpf);
  }

  public getLogUsers(data: string): Observable<MensagemListCommandResult> {
    return this.api.getLogUsers(data);
  }

  public getLogUsersMonth(data: string, idEmployee: string): Observable<MensagemListCommandResult> {
    return this.api.getLogUsersMonth(data, idEmployee);
  }

  public getHystoryTransfer(dealId: string): Observable<MensagemCommandResult> {
    return this.api.getHystoryTransfer(dealId);
  }

  public GetLogForClient(logUserRequest: LogUserListRequest): Observable<MensagemListCommandResult> {
    return this.api.GetLogForClient(logUserRequest);
  }

  public GetLogForClientDetails(logUserRequest: LogUserListRequest): Observable<MensagemListCommandResult> {
    return this.api.GetLogForClientDetails(logUserRequest);
  }

  public getLogDataBase(logUserRequest: LogUserListRequest): Observable<LogUserResult>{
    return this.api.getLogDataBase(logUserRequest);
  }

  public GetRankingLogAccess(): Observable<MensagemCommandResult> {
    return this.api.GetRankingLogAccess();
  }

  public GetRankingLogClient(): Observable<MensagemCommandResult> {
    return this.api.GetRankingLogClient();
  }

  public GetDealBusinessProbability(dealId: string): Observable<MensagemListCommandResult> {
    return this.api.GetDealBusinessProbability(dealId);
  }

  public ActivatedDeal(dealId: string): Observable<MensagemCommandResult> {
    return this.api.ActivatedDeal(dealId);
  }

  public UpdateDealClient(dataCustomer: DealDataCustomerCommandRequest): Observable<MensagemCommandResult> {
    return this.api.UpdateDealClient(dataCustomer);
  }

  public CreateDealClient(dataCustomer: DealDataCustomerCommandRequest): Observable<MensagemCommandResult> {
    return this.api.CreateDealClient(dataCustomer);
  }

  public GetBirthdays(): Observable<MensagemListCommandResult>{
    return this.api.GetBirthdays();
  }

  public GetPortifolioFake(dealId: string): Observable<MensagemListCommandResult>{
    return this.api.GetPortifolioFake(dealId);
  }

  public GetPortifolio(dealId: string): Observable<MensagemCommandResult>{
    return this.api.GetPortifolio(dealId);
  }

  public GetExistsClientImport(cpf: string): Observable<MensagemCommandResult>{
    return this.api.GetExistsClientImport(cpf);
  }

  public ResetSenhaSistema(id: string): Observable<MensagemCommandResult>{
    return this.api.ResetSenhaSistema(id);
  }

  public ResetSenhaUsuario(resetSenha: ResetSenhaSistemaCommand): Observable<MensagemCommandResult>{
    return this.api.ResetSenhaUsuario(resetSenha);
  }

  public GetUser(): Observable<MensagemCommandResult>{
   return this.api.GetUser();
  }

  public GetImportCpfs(report: GetImportCpfCommandRequest[]): Observable<MensagemCommandResult>{
    return this.api.GetImportCpfs(report);
  }

  public GetEmployee(idEmployee: string): Observable<EmployeeCommandResult> {
    return this.api.GetEmployee(idEmployee);
  }
  //#endregion
}
