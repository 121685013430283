import { ComissaoAnalise } from "./comissao-analise";
import { ComissaoExecutivoAnalise } from "./comissao-executivo-analise";
import { EnvioBancoNegocio } from "./envioBancoNegocio";
import { ContatoCliente } from "./contato-cliente";
import { DespesaNegocio } from "./despesa-negocio";
import { EmailCliente } from "./email-cliente";
import { HerdeiroCliente } from "./herdeiro-cliente";
import { PapelCliente } from "./papel-cliente";
import { TelefoneCliente } from "./telefone-cliente";
import Utils from "./utils";
import { ArquivoNegocio } from "./arquivo-negocio";
import { DirfCommandRequest } from "./DirfCommandRequest";

export class Negocio {

    id: string;
    employee: string;
    employeeLegal: string;
    name: string;
    employeeName: string;
    document: string;
    birthdate: string;
    deathdate: string;
    clientSituation: string;
    email: string;
    phone: string;
    city: string;
    state: string;
    company: string;
    priority: string;
    contacted: string;
    doneDeal: string;
    dealCompleted: string;
    dealJudicial: string;
    withLegalDepartment: string;
    // sentToBank: string;
    // sentToBankDate: string;
    confirmedRF: string;
    // confirmedBank: string;
    // protocol: string;
    censecRequested: string;
    type: string;
    situation: string;
    origin: string;
    originValue: number;
    confirmationDate: string;
    employeeLegalDate: string;
    createDate: string;
    offerPercentage: number;
    valueWillBeUpdated: boolean;
    amountToBePaid: number;
    advisoryPercentage: number;
    totalPriceAdvisory: number;
    phones: TelefoneCliente[] = [];
    emails: EmailCliente[] = [];
    portfolio: PapelCliente[] = [];
    contacts: ContatoCliente[] = [];
    heirs: HerdeiroCliente[] = [];

    hasOriginValue: boolean;
    totalPricePortfolio: number;
    totalFinalPricePortfolio: number;
    totalPriceOffer: number;

    expenses: DespesaNegocio[] = [];
    commissions: ComissaoAnalise[] = [];
    employeeCommissions: ComissaoExecutivoAnalise[] = [];
    files: ArquivoNegocio[] = [];
    // bankShipments: EnvioBancoNegocio[]=[];

    employeeLegalDays: number;
    // sentToBankDays: number;
    employeeTransf: string;
    clientHistory: string;
    dealProgressProcess_id: number;
    deleteBase: string;
    located: string;
    soldRedeemed: string;
    interest: string;
    loggedEmployeeId: string;
    idClientTemp: number;
    completeWork: string;
    closedPercentage: number;
    earnings: number;
    block: boolean;
    dirf: DirfCommandRequest;
    address: string;
    profession: string;
    deathData: string;

    constructor(values: {
        id: string,
        name: string,
        employee: string,
        employeeName: string,
        employeeLegal: string,
        document: string,
        birthdate: string,
        deathdate: string,
        clientSituation: string,
        email: string,
        phone: string,
        city: string,
        state: string,
        company: string,
        priority: string,
        contacted: string,
        doneDeal: string,
        dealCompleted: string,
        dealJudicial: string,
        withLegalDepartment: string,
        // sentToBank: string,
        // sentToBankDate: string,
        confirmedRF: string,
        // confirmedBank: string,
        // protocol: string,
        censecRequested: string,
        type: string,
        situation: string,
        origin: string,
        originValue: number,
        confirmationDate: string,
        employeeLegalDate: string,
        createDate: string,
        offerPercentage: number,
        valueWillBeUpdated: boolean,
        amountToBePaid: number,
        advisoryPercentage: number,
        totalPriceAdvisory: number,

        phones: TelefoneCliente[],
        emails: EmailCliente[],
        portfolio: PapelCliente[],
        expenses: DespesaNegocio[],
        commissions: ComissaoAnalise[],
        employeeCommissions: ComissaoExecutivoAnalise[];
        files: ArquivoNegocio[];
        // bankShipments: EnvioBancoNegocio[];
        contacts: ContatoCliente[],
        heirs: HerdeiroCliente[],

        employeeTransf: string,
        clientHistory: string,
        dealProgressProcess_id: number,
        deleteBase: string,
        located: string,
        soldRedeemed: string,
        interest: string,
        loggedEmployeeId: string,
        idClientTemp: number,
        completeWork: string,
        closedPercentage: number,
        earnings: number,
        block: boolean,
        dirf: DirfCommandRequest,
        profession: string,
        address: string,
        deathData: string
    }) {
        this.id = values.id
        this.name = values.name;
        this.employee = values.employee;
        this.employeeLegal = values.employeeLegal;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.birthdate = values.birthdate;
        this.deathdate = values.deathdate;
        this.clientSituation = values.clientSituation;
        this.email = values.email;
        this.phone = values.phone;
        this.city = values.city;
        this.state = values.state;
        this.company = values.company;
        this.priority = values.priority;
        this.contacted = values.contacted;
        this.doneDeal = values.doneDeal;
        this.dealCompleted = values.dealCompleted;
        this.dealJudicial = values.dealJudicial;
        this.withLegalDepartment = values.withLegalDepartment;
        // this.sentToBank = values.sentToBank;
        this.confirmedRF = values.confirmedRF;
        // this.confirmedBank = values.confirmedBank;
        // this.protocol = values.protocol;
        this.censecRequested = values.censecRequested;
        this.type = values.type;
        this.situation = values.situation;
        this.origin = values.origin;
        this.originValue = values.originValue;
        this.confirmationDate = values.confirmationDate;
        this.loggedEmployeeId = values.loggedEmployeeId;

        this.employeeLegalDate = values.employeeLegalDate;
        if (this.employeeLegalDate) {
            var dtSend = new Date(this.employeeLegalDate);
            var today = new Date();
            this.employeeLegalDays = Math.ceil(Math.abs(today.getTime() - dtSend.getTime()) / (1000 * 3600 * 24)) - 1;
        }

        // this.sentToBankDate = values.sentToBankDate;
        // if (this.sentToBankDate) {
        //     var dtSend = new Date(this.sentToBankDate);
        //     var today = new Date();
        //     this.sentToBankDays = Math.ceil(Math.abs(today.getTime() - dtSend.getTime()) / (1000 * 3600 * 24)) - 1;
        // }

        this.createDate = values.createDate;
        this.offerPercentage = values.offerPercentage;

        this.valueWillBeUpdated = values.valueWillBeUpdated == null ? false : values.valueWillBeUpdated;
        this.amountToBePaid = values.amountToBePaid;
        this.advisoryPercentage = values.advisoryPercentage;
        this.totalPriceAdvisory = values.totalPriceAdvisory;

        this.phones = values.phones;
        this.emails = values.emails;
        this.portfolio = values.portfolio;
        this.totalPricePortfolio = 0;
        this.portfolio.forEach(value => {
            this.totalPricePortfolio += value.totalPrice;
        });
        this.totalFinalPricePortfolio = 0;
        this.portfolio.forEach(value => {
            this.totalFinalPricePortfolio += value.finalPrice;
        });
        this.expenses = values.expenses;
        this.commissions = values.commissions;
        this.employeeCommissions = values.employeeCommissions;
        this.files = values.files;
        // this.bankShipments = values.bankShipments;
        this.contacts = values.contacts;
        this.heirs = values.heirs;
        if (this.origin) {
            var originValueArray: Array<string> = ['3', '4', '5', '6', '7', '8', '9'];
            this.hasOriginValue = originValueArray.includes(this.origin.toString());
        } else {
            this.origin = '0';
        }

        if (!this.clientSituation) {
            this.clientSituation = '0';
        }

        if (!this.situation) {
            this.situation = '0';
        }

        if (!this.employeeLegal) {
            this.employeeLegal = Utils.GUIDEmpty;
        }

        this.employeeTransf = values.employeeTransf;
        this.clientHistory = values.clientHistory;
        this.dealProgressProcess_id = values.dealProgressProcess_id;
        this.deleteBase = values.deleteBase;
        this.located = values.located;
        this.soldRedeemed = values.soldRedeemed;
        this.interest = values.interest;
        this.idClientTemp = values.idClientTemp;
        this.completeWork = values.completeWork;
        this.closedPercentage = values.closedPercentage;
        this.earnings = values.earnings;
        this.block = values.block;
        this.dirf = values.dirf;
        this.profession = values.profession;
        this.address = values.address;
        this.deathData = values.deathData;
    }
}
