export class LogCountResult {
  nameEmployee: string;
  count: number;

  constructor(values: { nameEmployee: string, count: number }) {

    this.nameEmployee = values.nameEmployee;
    this.count = values.count;
    
  }
}
