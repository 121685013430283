import { NegocioJuridico } from "./negocio-juridico";
import { ApiNotificacao } from "./api-notificacao";

export class NegocioJuridicoResult {
    success: boolean;
    data: NegocioJuridico;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}