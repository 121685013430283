import { MensagemCommandResult } from './../shared/mensagemResult';
import { User } from './../shared/user';
import { AccessScreen } from '../shared/AccessScreen';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestMethod, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { Acionista } from '../shared/acionista';
import { AcionistaResult } from '../shared/acionista-result';
import { DefaultResult } from '../shared/default-result';
import { Funcionario } from '../shared/funcionario';
import { FuncionariosCliente } from '../shared/funcionarios-cliente';
import { IpInfoResponse } from '../shared/ip-info-response';
import { Negocio } from '../shared/negocio';
import { NegocioJuridico } from '../shared/negocio-juridico';
import { NegocioJuridicoLista } from '../shared/negocio-juridico-lista';
import { NegocioJuridicoRealizado } from '../shared/negocio-juridico-realizado';
import { NegocioJuridicoRealizadoLista } from '../shared/negocio-juridico-realizado-lista';
import { NegocioJuridicoRealizadoResult } from '../shared/negocio-juridico-realizado-result';
import { NegocioJuridicoResult } from '../shared/negocio-juridico-result';
import { NegocioLista } from '../shared/negocio-lista';
import { NegocioRealizado } from '../shared/negocio-realizado';
import { NegocioRealizadoLista } from '../shared/negocio-realizado-lista';
import { NegocioRealizadoResult } from '../shared/negocio-realizado-result';
import { NegocioResult } from '../shared/negocio-result';
import { Papel } from '../shared/papel';
import { Parceiro } from '../shared/parceiro';
import { Apontamento } from '../shared/apontamento';
import { ParceiroResult } from '../shared/parceiro-result';
import { ApontamentoResult } from '../shared/apontamento-result';
import { RegisterAccess } from '../shared/register-access';
import { Permissao } from '../shared/permissao';
import { DefaultData } from '../shared/default-data';
import { PagamentoColaborador } from '../shared/pagamentocolaborador';
import { PagamentoColaboradorResult } from '../shared/pagamentocolaborador-result';
import { ContatoCliente } from '../shared/contato-cliente';
import { PopupEmployee } from '../shared/popup-employee';
import { CreateUser } from '../shared/createUser';
import { AccessMenu } from '../shared/AccessMenu';
import { AccessPermissionsPerfil } from '../shared/AccessPermissionsPerfil';
import { AccessPermissions } from '../shared/AccessPermissions';
import { deal } from '../shared/deal';
import { UpdateDealEmployee } from '../shared/update-deal-employee';
import { ClientHistoryResult } from '../shared/ClientHistoryResult';
import { TickerSymbol } from '../shared/TickerSymbol';
import { TickerSymbolHistory } from '../shared/TickerSymbolHistory';
import { ProcessoTickerSymbol } from '../shared/ProcessoTickerSymbol';
import { LogUserResult } from '../shared/logUserResult';
import { TickerSymbolHistoryDate } from '../shared/TickerSymbolHistory-date';
import { ReportClientRequest } from '../shared/ReportClientRequest';
import { ReportClientResult } from '../shared/ReportClientResult';
import { StatusNegocio } from '../shared/statusNegocio';
import { LogCountResult } from '../shared/logCountResult';
import { MensagemListCommandResult } from '../shared/mensagemListResult';
import { LogUserListRequest } from '../shared/logUserListRequest';
import { UniquePermissionCommandResult } from '../shared/UniquePermissionCommandResult';
import { catchError, map } from 'rxjs/operators';
import { EmployeeTypeCommandResult } from '../shared/EmployeeTypeCommandResult';
import { GetImportReportCommand } from '../shared/GetImportReportCommand';
import { DealDataCustomerCommandRequest } from '../shared/DealDataCustomerCommandRequest';
import { ResetSenhaSistemaCommand } from '../shared/ResetSenhaSistemaCommand';
import { GetImportCpfCommandRequest } from '../shared/GetImportCpfCommandRequest';
import { EmployeeCommandResult } from '../shared/EmployeeCommandResult';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: Http,
    private httpClient: HttpClient,
    private authService: NbAuthService) {

    this.registerAccessChange();
    this.registerUserIP();
    this.registerUserGeolocation();

  }

  private token: NbAuthJWTToken = null;
  private employee = '';
  private regAcesso: RegisterAccess = new RegisterAccess();
  private etapasAcesso: number = 4;
  private etapasAcessoProcessadas: number = 0;
  private permissoesUnicas: UniquePermissionCommandResult[] = [];
  private tipoUsuario: string;
  private resetSenha: boolean;

  //#region users register
  private registerAccessChange() {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.token = token;
          var objUser = token.getPayload();

          this.employee = objUser.employee;
          this.regAcesso.idEmployee = objUser.employee;
          this.regAcesso.userName = objUser.name;
          this.regAcesso.email = objUser.email;
          this.regAcesso.birthDate = objUser.birthDate;

          //Primeira Etapa - Dados do usuário
          this.registerAccess().subscribe(dados => null);
          this.PermissaoEmployeeList(objUser.employee).subscribe(
            permissions => {
              this.permissoesUnicas = permissions;
            },
            error => {
              // Lida com erro no carregamento das permissões
            });


            this.RequestTypeUser(objUser.employee).subscribe(
              dados => {
                this.tipoUsuario = dados.description;
              },
              error => {
                // Lida com erro no carregamento das permissões
              });

            this.RequestResetPassword(objUser.employee).subscribe(
              dados => {
                this.resetSenha = dados.valida;
              }
            )
        }
      }, error => {
        //Primeira Etapa - Dados do usuário
        this.registerAccess().subscribe(dados => null);
      });
  }

  private registerUserIP() {
    this.httpClient.get<IpInfoResponse>('https://api.ipify.org/?format=json')
      .subscribe(data => {

        this.regAcesso.ip = data.ip;
        let userIp = data.ip;

        this.registerUserIpLocation(userIp);

        //Segunda Etapa - IP
        this.registerAccess().subscribe(dados => null);
      }, error => {
        //Segunda Etapa - IP
        this.registerAccess().subscribe(dados => null);
      });
  }

  private registerUserIpLocation(userIp: string) {

    this.httpClient.get<IpInfoResponse>('https://ipapi.co/' + userIp + '/json/')
      .subscribe(data => {

        this.regAcesso.countryName = data.country_name;
        this.regAcesso.regionCode = data.region_code;
        this.regAcesso.regionName = data.region;
        this.regAcesso.city = data.city;
        this.regAcesso.latitude = Number.parseFloat(data.latitude);
        this.regAcesso.longitude = Number.parseFloat(data.longitude);

        //Quarta Etapa - Localização do IP
        this.registerAccess().subscribe(dados => null);
      }, error => {
        //Quarta Etapa - Localização do IP
        this.registerAccess().subscribe(dados => null);
      });
  }

  private registerUserGeolocation() {

    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {

        this.regAcesso.latitude = position.coords.latitude;
        this.regAcesso.longitude = position.coords.longitude;
        this.regAcesso.statusLocalizacao = 'Posição obtida';

        //Quarta Etapa - Localização do Usuário
        this.registerAccess().subscribe(dados => null);
      }, error => {
        switch (error.code) {
          case 1:
            this.regAcesso.statusLocalizacao = 'Permissão negada';
            break;
          case 2:
            this.regAcesso.statusLocalizacao = 'Posição indisponível';
            break;
          case 3:
            this.regAcesso.statusLocalizacao = 'Tempo esgotado';
            break;
          default:
            this.regAcesso.statusLocalizacao = 'Indefinido';
            break;
        }

        //Quarta Etapa - Localização do Usuário
        this.registerAccess().subscribe(dados => null);
      });
    }
    else {
      //Quarta Etapa - Localização do Usuário
      this.registerAccess().subscribe(dados => null);
    }
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Bearer ' + this.token);
  }

  public registerAccess(): Observable<null> {

    this.etapasAcessoProcessadas += 1;

    if (this.etapasAcessoProcessadas == this.etapasAcesso) {

      this.etapasAcessoProcessadas = 0;

      let headers = new Headers();
      this.createAuthorizationHeader(headers);

      return this.http
        .post(API_URL + '/auth/register-access', this.regAcesso, {
          headers: headers
        })
        .map(response => null)
        .catch(this.handleError);
    }
    else {
      return new Observable<null>();
    }
  }
  //#endregion

  //#region Deals
  public getAllDeals(dtRetIni: Date, dtRetFim: Date, excluirBase: string): Observable<NegocioLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtReturnIni = '';
    if (dtRetIni)
      dtReturnIni = dtRetIni.toISOString();

    let dtReturnFim = '';
    if (dtRetFim)
      dtReturnFim = dtRetFim.toISOString();

    return this.http
      .get(API_URL + '/deals?dtRetIni=' + dtReturnIni + '&dtRetFim=' + dtReturnFim + '&excluirBase=' + excluirBase, { headers: headers })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioLista(deal));
      })
      .catch(this.handleError);
  }


public getDealsBySearch(search: string, searchDoc: string, searchCity: string, dtNegIni: Date, dtNegFim: Date, dtContIni: Date, dtContFim: Date, dtIni: Date,
  dtFim: Date, sit: string, cliSit: string, compartilhado: string, employee: string, employeeLegal: string, tpNeg: string, fechado: string, finalizado: string,
  judicial: string, comJuridico: string, enviadoBanco: string, cadastro: string, localizado: string, contatado: string, vendeuResgatou: string, temInteresse: string,
  excluirBase: string, selectRealtor: string): Observable<NegocioLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtNegocioIni = '';
    if (dtNegIni)
      dtNegocioIni = dtNegIni.toISOString();

    let dtNegocioFim = '';
    if (dtNegFim)
      dtNegocioFim = dtNegFim.toISOString();

    let dtContactIni = '';
    if (dtContIni)
      dtContactIni = dtContIni.toISOString();

    let dtContactFim = '';
    if (dtContFim)
      dtContactFim = dtContFim.toISOString();

    let dtRetIni = '';
    if (dtIni)
      dtRetIni = dtIni.toISOString();

    let dtRetFim = '';
    if (dtFim)
      dtRetFim = dtFim.toISOString();

    return this.http
      .get(API_URL + '/deals?name=' + search + '&document=' + searchDoc + '&city=' + searchCity + '&dtNegIni=' + dtNegocioIni + '&dtNegFim=' + dtNegocioFim + '&dtContactIni=' +
      dtContactIni + '&dtContactFim=' + dtContactFim + '&dtRetIni=' + dtRetIni + '&dtRetFim=' + dtRetFim + '&sit=' + sit + '&cliSit=' + cliSit + '&shared=' +
       compartilhado + '&emp=' + employee + '&empLegal=' + employeeLegal + '&tpDeal=' + tpNeg + '&doneDeal=' + fechado + '&dealCompleted=' + finalizado + '&dealJudicial=' +
       judicial + '&withLegalDepartment=' + comJuridico + '&sentToBank=' + enviadoBanco + '&company=' + cadastro + '&localizado=' + localizado + '&contatado=' +
       contatado + '&vendeuResgatou=' + vendeuResgatou + '&temInteresse=' + temInteresse + "&excluirBase=" + excluirBase + "&selectRealtor=" + selectRealtor, { headers: headers })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioLista(deal));
      })
      .catch(this.handleError);
  }

  public createDeal(deal: Negocio): Observable<NegocioResult> {

    if (!deal.employee) {
      deal.employee = this.employee;
    }

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/deals', deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }

  public getPermission(authorized: string): Observable<Permissao> {
    let headers = new Headers();
    let objRequest = null;
    this.createAuthorizationHeader(headers);

    objRequest = { authorized: authorized };

    return this.http
      .get(API_URL + '/dealpermission/' + authorized, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());
        if ((res) == JSON.stringify({})) {
          return null;
        }
        else {
          return new Permissao(response.json());
        }
      })
      .catch(this.handleError);
  }

  public addPermission(transferir: Boolean, somenteLeitura: Boolean, authorized: string, employees: string[], permitted: string, expiryDate: Date): Observable<DefaultResult> {

    let headers = new Headers();
    let objRequest = null;
    this.createAuthorizationHeader(headers);

    if (permitted) {
      objRequest = { transfer: transferir, readOnly: somenteLeitura, permitted: permitted, authorized: authorized, expiryDate: expiryDate, employees: employees };
    } else {
      objRequest = { transfer: transferir, readOnly: somenteLeitura, authorized: authorized, expiryDate: expiryDate, employees: employees };
    }

    return this.http
      .post(API_URL + '/dealpermission/', objRequest, {
        headers: headers
      })
      .map(response => {
        return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

  public getDealById(dealId: string): Observable<Negocio> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/deals/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new Negocio(response.json());
      })
      .catch(this.handleError);
  }

  public getDealByDocument(employee: string, document: string): Observable<Negocio> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let deal = { employee: employee, document: document };

    return this.http
      .post(API_URL + '/dealbydoc', deal, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new Negocio(response.json());
      })
      .catch(this.handleError);
  }

  public updateDeal(deal: Negocio): Observable<NegocioResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .put(API_URL + '/deals/' + deal.id, deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }

  public deleteDealById(dealId: string): Observable<null> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .delete(API_URL + '/deals/' + dealId, {
        headers: headers
      })
      .map(response => null)
      .catch(this.handleError);
  }
  //#endregion

  //#region DoneDeals
  public getAllDoneDeals(): Observable<NegocioRealizadoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/donedeals', {
        headers: headers
      })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioRealizadoLista(deal));
      })
      .catch(this.handleError);
  }

  public getDoneDealsBySearch(search: string, searchDoc: string, dtIni: Date, dtFim: Date, employee: string): Observable<NegocioRealizadoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtDoneIni = '';
    if (dtIni)
      dtDoneIni = dtIni.toISOString();

    let dtDoneFim = '';
    if (dtFim)
      dtDoneFim = dtFim.toISOString();

    return this.http
      .get(API_URL + '/donedeals?name=' + search + '&document=' + searchDoc + '&dtDoneIni=' + dtDoneIni + '&dtDoneFim=' + dtDoneFim + '&emp=' + employee, { headers: headers })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioRealizadoLista(deal));
      })
      .catch(this.handleError);
  }

  public createDoneDeal(deal: NegocioRealizado): Observable<NegocioRealizadoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/donedeals', deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioRealizadoResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }

  public getDoneDealById(dealId: string): Observable<NegocioRealizado> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/donedeals/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new NegocioRealizado(response.json());
      })
      .catch(this.handleError);
  }

  public getContactOrderById(dealId: string): Observable<ContatoCliente> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/contacts/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new ContatoCliente(response.json());
      })
      .catch(this.handleError);
  }

  public getContactClient(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getContactToday', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public CreateUser(docFunc: CreateUser): Observable<CreateUser> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/createUser', docFunc, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new CreateUser(response.json());
      })
      .catch(this.handleError);
  }

  public updatePassword(Id: String, Password: String): Observable<DefaultResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let resetPass = { Id: Id, Password: Password };

    return this.http
      .post(API_URL + '/auth/reset-pass', resetPass, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

   public GetPopupEmployee(employeeId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/popupEmployee/' + employeeId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public updateDoneDeal(deal: NegocioRealizado): Observable<NegocioRealizadoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .put(API_URL + '/donedeals/' + deal.id, deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioRealizadoResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }
  //#endregion

  //#region DoneDeals - Jurídico
  public getAllDoneDealsJuridico(): Observable<NegocioJuridicoRealizadoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/jdonedeals', {
        headers: headers
      })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioJuridicoRealizadoLista(deal));
      })
      .catch(this.handleError);
  }

  public getDoneDealsJuridicoBySearch(search: string, searchDoc: string, dtIni: Date, dtFim: Date): Observable<NegocioJuridicoRealizadoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtDoneIni = '';
    if (dtIni)
      dtDoneIni = dtIni.toISOString();

    let dtDoneFim = '';
    if (dtFim)
      dtDoneFim = dtFim.toISOString();


    return this.http
      .get(API_URL + '/jdonedeals?name=' + search + '&document=' + searchDoc + '&dtDoneIni=' + dtDoneIni + '&dtDoneFim=' + dtDoneFim, { headers: headers })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioJuridicoRealizadoLista(deal));
      })
      .catch(this.handleError);
  }

  public createDoneDealJuridico(deal: NegocioJuridicoRealizado): Observable<NegocioJuridicoRealizadoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/jdonedeals', deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioJuridicoRealizadoResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }

  public getDoneDealJuridicoById(dealId: string): Observable<NegocioJuridicoRealizado> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/jdonedeals/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new NegocioJuridicoRealizado(response.json());
      })
      .catch(this.handleError);
  }

  public updateDoneDealJuridico(deal: NegocioJuridicoRealizado): Observable<NegocioJuridicoRealizadoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .put(API_URL + '/jdonedeals/' + deal.id, deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioJuridicoRealizadoResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }
  //#endregion

  //#region Deals - Jurídico
  public getAllDealsJuridico(): Observable<NegocioJuridicoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/jdeals', {
        headers: headers
      })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioJuridicoLista(deal));
      })
      .catch(this.handleError);
  }

  public getDealsJuridicoBySearch(search: string, searchDoc: string, searchCity: string, dtCrtIni: Date, dtCrtFim: Date, dtIni: Date, dtFim: Date): Observable<NegocioJuridicoLista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtCreateIni = '';
    if (dtCrtIni)
      dtCreateIni = dtCrtIni.toISOString();

    let dtCreateFim = '';
    if (dtCrtFim)
      dtCreateFim = dtCrtFim.toISOString();

    let dtRetIni = '';
    if (dtIni)
      dtRetIni = dtIni.toISOString();

    let dtRetFim = '';
    if (dtFim)
      dtRetFim = dtFim.toISOString();

    return this.http
      .get(API_URL + '/jdeals?name=' + search + '&document=' + searchDoc + '&city=' + searchCity + '&dtCrtIni=' + dtCreateIni + '&dtCrtFim=' + dtCreateFim + '&dtRetIni=' + dtRetIni + '&dtRetFim=' + dtRetFim, { headers: headers })
      .map(response => {
        const deals = response.json();
        return deals.map((deal) => new NegocioJuridicoLista(deal));
      })
      .catch(this.handleError);
  }

  public createDealJuridico(deal: NegocioJuridico): Observable<NegocioJuridicoResult> {

    if (!deal.employee) {
      deal.employee = this.employee;
    }

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/jdeals', deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioJuridicoResult(response.json());
        return neg;
      })
      .catch(this.handleError);
  }

  public getDealJuridicoById(dealId: string): Observable<NegocioJuridico> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/jdeals/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new NegocioJuridico(response.json());
      })
      .catch(this.handleError);
  }

  public getDealJuridicoByDocument(employee: string, document: string): Observable<NegocioJuridico> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let deal = { employee: employee, document: document };

    return this.http
      .post(API_URL + '/jdealbydoc', deal, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new NegocioJuridico(response.json());
      })
      .catch(this.handleError);
  }

  public updateDealJuridico(deal: NegocioJuridico): Observable<NegocioJuridicoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .put(API_URL + '/jdeals/' + deal.id, deal, {
        headers: headers
      })
      .map(response => {
        let neg = new NegocioJuridicoResult(response.json());
        return neg;
      })
      .catch(this.handleError);
  }

  public deleteDealJuridicoById(dealId: string): Observable<null> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .delete(API_URL + '/jdeals/' + dealId, {
        headers: headers
      })
      .map(response => null)
      .catch(this.handleError);
  }
  //#endregion

  //#region Corresponding
  public getAllCorresponding(): Observable<Parceiro[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/partners/2', {
        headers: headers
      })
      .map(response => {
        const parceiros = response.json();
        return parceiros.data.partners.map((parceiro) => new Parceiro(parceiro));
      })
      .catch(this.handleError);
  }

  public getAllNotes(): Observable<Apontamento[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/employeeNotes', {
        headers: headers
      })
      .map(response => {
        const apontamentos = response.json();
        return apontamentos.map((apontamento) => new Apontamento(apontamento));
      })
      .catch(this.handleError);
  }

  public getCorrespondingByDocument(documento: string): Observable<Parceiro> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/partners/', { document: documento }, {
        headers: headers
      })
      .map(response => {
        const parceiros = response.json();
        let partners = parceiros.data.partners.map((parceiro) => new Parceiro(parceiro));
        return partners[0];
      })
      .catch(this.handleError);
  }

  public saveCorresponding(partner: Parceiro): Observable<ParceiroResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let partnerSave = { id: partner.id, name: partner.name, document: partner.document, type: partner.type };

    return this.http
      .put(API_URL + '/partner/', partnerSave, {
        headers: headers
      })
      .map(response => {
        let neg = new ParceiroResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }
  //#endregion

  public saveNote(note: Apontamento): Observable<ApontamentoResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);


    let noteSave = { id: note.id, description: note.description, hours: note.hours, referenceDate: note.referenceDate, createdDate: note.createdDate, employeeId: note.employeeId, companyId: note.companyId };

    return this.http
      .post(API_URL + '/employeeNotes/', noteSave, {
        headers: headers
      })
      .map(response => {
        let neg = new ApontamentoResult(response.json());
        return neg;
      })
      .catch(this.handleError);

  }

  //#region Auxiliares

  public getAllCompanies(): Observable<DefaultData[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/companies', {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        return objRes.data.companies.map((obj) => new DefaultData(obj));
      })
      .catch(this.handleError);
  }

  public getAllUnits(): Observable<DefaultData[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/units', {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        return objRes.data.units.map((obj) => new DefaultData(obj));
      })
      .catch(this.handleError);
  }

  public getAllFixExpenses(): Observable<DefaultData[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/fixexpenses', {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        return objRes.data.fixExpenses.map((obj) => new DefaultData(obj));
      })
      .catch(this.handleError);
  }

  public getAllVariableExpenses(): Observable<DefaultData[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/variableexpenses', {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        return objRes.data.variableExpenses.map((obj) => new DefaultData(obj));
      })
      .catch(this.handleError);
  }

  public saveCompany(data: DefaultData): Observable<DefaultResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dataSave = { id: data.id, name: data.name };

    return this.http
      .post(API_URL + '/company/', dataSave, {
        headers: headers
      })
      .map(response => {
        return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

  public saveUnit(data: DefaultData): Observable<DefaultResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dataSave = { id: data.id, name: data.name };

    return this.http
      .post(API_URL + '/unit/', dataSave, {
        headers: headers
      })
      .map(response => {
        return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

  public saveFixExpense(data: DefaultData): Observable<DefaultResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dataSave = { id: data.id, name: data.name };

    return this.http
      .post(API_URL + '/fixexpense/', dataSave, {
        headers: headers
      })
      .map(response => {
        return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

  public saveVariableExpense(data: DefaultData): Observable<DefaultResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dataSave = { id: data.id, name: data.name };

    return this.http
      .post(API_URL + '/variableexpense/', dataSave, {
        headers: headers
      })
      .map(response => {
        return new DefaultResult(response.json());
      })
      .catch(this.handleError);
  }

  //#endregion


  //#region Shareholder
  public getShareholder(shareholderId: string): Observable<Acionista> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/shareholders/', { id: shareholderId }, {
        headers: headers
      })
      .map(response => {
        const acionistas = response.json();
        let shareholders = acionistas.data.shareholders.map((acionista) => new Acionista(acionista));
        return shareholders[0];
      })
      .catch(this.handleError);
  }

  public getShareholderByDocument(document: string): Observable<Acionista> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/shareholders/', { document: document }, {
        headers: headers
      })
      .map(response => {
        const acionistas = response.json();
        let shareholders = acionistas.data.shareholders.map((acionista) => new Acionista(acionista));
        return shareholders[0];
      })
      .catch(this.handleError);
  }

  public saveShareholder(shareholder: Acionista): Observable<AcionistaResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let shareholderSave = { id: shareholder.id, name: shareholder.name, document: shareholder.document, portfolio: shareholder.portfolio };

    return this.http
      .put(API_URL + '/shareholder/', shareholderSave, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new AcionistaResult(response.json());
      })
      .catch(this.handleError);

  }

  public deleteShareholder(shareholderId: string): Observable<AcionistaResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .delete(API_URL + '/shareholders/' + shareholderId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new AcionistaResult(response.json());
      })
      .catch(this.handleError);
  }

  public getAllShareholders(): Observable<Acionista[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/shareholders', {
        headers: headers
      })
      .map(response => {
        const acionistas = response.json();
        return acionistas.data.shareholders.map((acionista) => new Acionista(acionista));
      })
      .catch(this.handleError);
  }

  public GetAllUser(): Observable<User[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/listUser', {
        headers: headers
      })
      .map(response => {
        const user = response.json();
        return user.map((user) => new User(user));
      })
      .catch(this.handleError);
  }
  //#endregion


  public getAllEmployeePayments(): Observable<PagamentoColaborador[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/employeePayments/all', {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        return objRes.data.employeePayments.map((employeePayment) => new PagamentoColaborador(employeePayment));
      })
      .catch(this.handleError);
  }


  //#region Expense


  public getEmployeePayment(employeePaymentId: string): Observable<PagamentoColaborador> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/employeePayment/', { id: employeePaymentId }, {
        headers: headers
      })
      .map(response => {
        const objRes = response.json();
        let employeePayments = objRes.data.employeePayments.map((employeePayment) => new PagamentoColaborador(employeePayment));
        return employeePayments[0];
      })
      .catch(this.handleError);
  }



  public saveEmployeePayment(employeePayment: PagamentoColaborador): Observable<PagamentoColaboradorResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let employeePaymentSave = {
      id: employeePayment.id,
      date: employeePayment.date,
      referenceDate: employeePayment.referenceDate,
      company: employeePayment.company.id,
      unit: employeePayment.unit.id,
      employee: employeePayment.employee.id,
      fixedValue: employeePayment.fixedValue,
      variableValue: employeePayment.variableValue,
      transportationVoucher: employeePayment.transportationVoucher,
      mealTicket: employeePayment.mealTicket,
      otherValueType: employeePayment.otherValueType,
      otherValue: employeePayment.otherValue,
      totalValue: employeePayment.totalValue
    };

    return this.http
      .put(API_URL + '/employeePayment/', employeePaymentSave, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new PagamentoColaboradorResult(response.json());
      })
      .catch(this.handleError);
  }



  public deleteEmployeePayment(employeePaymentId: string): Observable<PagamentoColaboradorResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .delete(API_URL + '/employeePayment/' + employeePaymentId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new PagamentoColaboradorResult(response.json());
      })
      .catch(this.handleError);
  }


  //#endregion
  public getEmployeePaymentsBySearch(dtIni: Date, dtFim: Date, company: string, unit: string, employee: string): Observable<PagamentoColaborador[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    let dtPagIni = '';
    if (dtIni)
      dtPagIni = dtIni.toISOString();

    let dtPagFim = '';
    if (dtFim)
      dtPagFim = dtFim.toISOString();

    return this.http
      .get(API_URL + '/employeePayments?dtPagIni=' + dtPagIni + '&dtPagFim=' + dtPagFim + '&company=' + company + '&unit=' + unit + '&employee=' + employee, { headers: headers })
      .map(response => {
        const objRes = response.json();
        return objRes.data.employeePayments.map((expense) => new PagamentoColaborador(expense));
      })
      .catch(this.handleError);
  }
  //#region outros
  public getAllPapeis(): Observable<Papel[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/symbols', {
        headers: headers
      })
      .map(response => {
        const symbols = response.json();
        return symbols.map((symbol) => new Papel(symbol));
      })
      .catch(this.handleError);
  }

  public getAllCorretores(): Observable<Funcionario[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/employees', {
        headers: headers
      })
      .map(response => {
        const funcionarios = response.json();
        return funcionarios.map((funcionario) => new Funcionario(funcionario));
      })
      .catch(this.handleError);
  }

  public getAllAnalistasProperties(): Observable<Funcionario[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/jemployees', {
        headers: headers
      })
      .map(response => {
        const funcionarios = response.json();
        return funcionarios.map((funcionario) => new Funcionario(funcionario));
      })
      .catch(this.handleError);
  }

  public getAllInvestidores(): Observable<Parceiro[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/partners/1', {
        headers: headers
      })
      .map(response => {
        const parceiros = response.json();
        return parceiros.data.partners.map((parceiro) => new Parceiro(parceiro));
      })
      .catch(this.handleError);
  }

  public getAllResponsaveis(): Observable<Parceiro[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/partners/4', {
        headers: headers
      })
      .map(response => {
        const parceiros = response.json();
        return parceiros.data.partners.map((parceiro) => new Parceiro(parceiro));
      })
      .catch(this.handleError);
  }

  public clienteExiste(documento: string): Observable<FuncionariosCliente> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/isclient/' + documento, {
        headers: headers
      })
      .map(response => {
        let neg = new FuncionariosCliente(response.json());
        return neg;
      })
      .catch(this.handleError);
  }

  public clienteJuridicoExiste(documento: string): Observable<FuncionariosCliente> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/isjclient/' + documento, {
        headers: headers
      })
      .map(response => {
        let neg = new FuncionariosCliente(response.json());
        return neg;
      })
      .catch(this.handleError);
  }

  public isMasterUser(): boolean {

    let pl = this.token.getPayload();
    let ismu: boolean = pl.GestaoReno && pl.GestaoReno == 'MasterCapital';
    if (ismu) {
      return true;
    }

    ismu = pl.GestaoRenoJuridico && pl.GestaoRenoJuridico == 'MasterProperties';
    if (ismu) {
      return true;
    }

    return false;
  }

  public isAdminUser(): boolean {

    let pl = this.token.getPayload();

    let isadm: boolean = pl.GestaoReno && pl.GestaoReno === 'Administrator';
    if (isadm) {
      return true;
    }

    isadm = pl.GestaoReno && pl.GestaoReno === 'MasterCapital';
    if (isadm) {
      return true;
    }

    isadm = pl.GestaoRenoJuridico && pl.GestaoRenoJuridico === 'MasterProperties';
    if (isadm) {
      return true;
    }

    return false;
  }

  public getEmployee(): string {

    let pl = this.token.getPayload();
    return pl.employee;
  }

  public getEmployeeName(): string {

    let pl = this.token.getPayload();
    return pl.name;
  }

  public getEmployeeBirthDate(): string {

    let pl = this.token.getPayload();
    return pl.birthDate;
  }

  public isGestaoReno(): boolean {

    let pl = this.token.getPayload();

    if (pl.GestaoReno) {
      return true;
    }

    return false;
  }

  public isGestaoRenoJuridico(): boolean {

    let pl = this.token.getPayload();

    if (pl.GestaoRenoJuridico) {
      return true;
    }

    return false;
  }

  public isClientManagerUser(): boolean {

    let pl = this.token.getPayload();

    if (pl.ClientManager) {
      return true;
    }

    return false;
  }

  public getPermissaoUnica(code: number): boolean {
    let permissaoUnica = this.permissoesUnicas;

    if (permissaoUnica.find(s => s.code == code)){
      return true
    } else {
      return false;
    }
  }

  public getTipoUsuario(): string {
    return this.tipoUsuario;
  }

  public getResetSenha(): boolean {
    return this.resetSenha;
  }

  private handleError(error: Response | any) {
    let details = JSON.stringify(error.json());
    console.error('ApiService::handleError', details);
    return Observable.throw(details);
  }

  public getLog(dtLog: string): Observable<Blob> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    const options = new RequestOptions({
      headers: headers,
      method: RequestMethod.Get,
      responseType: ResponseContentType.Blob
    });

    return this.http
      .get(API_URL + '/log/' + dtLog, options)
      .map(response => {
        if (response.ok) {
          return new Blob([response.blob()], { type: 'application/octet-stream' });
        }
      })
      .catch(this.handleError);
  }

  public getLogDataBase(logUserRequest: LogUserListRequest): Observable<LogUserResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/logDataBase', logUserRequest, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new LogUserResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetAccessScreen(Menu: string): Observable<AccessScreen[]> {

      let headers = new Headers();
      this.createAuthorizationHeader(headers);

      return this.http
        .get(API_URL + '/listAccessScreen/' + Menu, {
          headers: headers
        })
        .map(response => {
          const accessScreen = response.json();
          return accessScreen.map((accessScreen) => new AccessScreen(accessScreen));
        })
        .catch(this.handleError);
  }

  public GetAccessScreenProfile(Menu: string, userProfile: string): Observable<AccessScreen[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/listAccessScreenProfile/' + Menu + ',' + userProfile, {
        headers: headers
      })
      .map(response => {
        const accessScreen = response.json();
        return accessScreen.map((accessScreen) => new AccessScreen(accessScreen));
      })
      .catch(this.handleError);
  }

  public GetAccessMenu(): Observable<AccessMenu[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/listAccessMenu', {
        headers: headers
      })
      .map(response => {
        const accessMenu = response.json();
        return accessMenu.map((accessMenu) => new AccessMenu(accessMenu));
      })
      .catch(this.handleError);
}

public GetListAccessPermissions(IdEmployee: string): Observable<MensagemListCommandResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/listAccessPermissions/' + IdEmployee, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new MensagemListCommandResult(response.json());
    })
    .catch(this.handleError);
}

public GetAccessPermissions(IdEmployee: string, nomeMenu: string, nomeScreen: string): Observable<MensagemCommandResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/getAccessPermissions/' + IdEmployee + ',' + nomeMenu + ',' + nomeScreen, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new MensagemCommandResult(response.json());
    })
    .catch(this.handleError);
}

public GetPerfilUsuario(IdEmployee: string): Observable<AccessPermissionsPerfil> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/buscaPerfilUsuario/' + IdEmployee, {
      headers: headers
    })
    .map(response => {
      let neg = new AccessPermissionsPerfil(response.json());
      return neg;
    })
    .catch(this.handleError);
}

public createListEmployeeAccess(listaAccess: AccessPermissions[]): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/createListEmployeeAccess' + listaAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}

public createEmployeeAccess(itemAccess: AccessPermissions): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/createEmployeeAccess', itemAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}

public CreateEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/createEmployeeAccessProfile', itemAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}

public UpdateEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/updateEmployeeAccessProfile', itemAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}

public ValidaEmployeeAccessPerfil(itemAccess: AccessPermissions): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/validaEmployeeAccessProfile', itemAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}


public deleteEmployeeAccess(itemAccess: AccessPermissions): Observable<AccessPermissions> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/deleteEmployeeAccess', itemAccess, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new AccessPermissions(response.json());
    })
    .catch(this.handleError);
}
public GetDealsIds(id: string): Observable<deal> {
  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/getDealsIds/' + id, {
      headers: headers
    })
    .map(response => {
      let neg = new deal(response.json());
      return neg;
    })
    .catch(this.handleError);
}

public GetAccessEmployeeUser(idEmployee: string): Observable<User> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/accessEmployeeUser/' + idEmployee, {
      headers: headers
    })
    .map(response => {
      let neg = new User(response.json());
      return neg;
    })
    .catch(this.handleError);
}

public getValidaSenha(senha: string): Observable<User> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/validarSenha/' + senha, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new User(response.json());
    })
    .catch(this.handleError);
}

public getAtualizarSenha(user: User): Observable<User>{

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/atualizarSenha', user, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new User(response.json());
    })
    .catch(this.handleError);
}

public updateDealEmployee(dealEmployee: UpdateDealEmployee): Observable<MensagemCommandResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/updateDealEmployee', dealEmployee, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new MensagemCommandResult(response.json());
    })
    .catch(this.handleError);
}


public GetClientHistory(dealId: string): Observable<ClientHistoryResult[]> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .get(API_URL + '/getClientHistory/' + dealId, {
      headers: headers
    })
    .map(response => {
      // let neg = new ClientHistoryResult(response.json());
      // return neg;
      const clienteHistory = response.json();
      return clienteHistory.map((clienteHistory) => new ClientHistoryResult(clienteHistory));
    })
    .catch(this.handleError);
}

public createClienteHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/createClienteHistory', clienteHistory, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new ClientHistoryResult(response.json());
    })
    .catch(this.handleError);
}

public UpdateClientHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/updateClientHistory', clienteHistory, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new ClientHistoryResult(response.json());
    })
    .catch(this.handleError);
}

public DeleteClientHistory(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {

  let headers = new Headers();
  this.createAuthorizationHeader(headers);

  return this.http
    .post(API_URL + '/deleteClientHistory', clienteHistory, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new ClientHistoryResult(response.json());
    })
    .catch(this.handleError);
  }

  public requestLastDateUpdate(): Observable<Date> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/requestLastDateUpdate', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new Date(response.json());
      })
      .catch(this.handleError);
  }

  public carregaTickerSymbol(): Observable<TickerSymbol[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getSymbols', {
        headers: headers
      })
      .map(response => {
        const tickerSymbol = response.json();
        return tickerSymbol.map((tickerSymbol) => new TickerSymbol(tickerSymbol));
      })
      .catch(this.handleError);
  }

  public carregaTickerSymbolHistory(tickerSymbolHistoryDate: TickerSymbolHistoryDate): Observable<TickerSymbolHistory[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/symbolsHistory', tickerSymbolHistoryDate, {
        headers: headers
      })
      .map(response => {
        const tickerSymbolHistory = response.json();
        return tickerSymbolHistory.map((tickerSymbolHistory) => new TickerSymbolHistory(tickerSymbolHistory));
      })
      .catch(this.handleError);
  }

  public ExecutarTickerSymbol(idEmployee: string): Observable<ProcessoTickerSymbol> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/processConsole/' + idEmployee, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new ProcessoTickerSymbol(response.json());
      })
      .catch(this.handleError);
  }

  public getRequestSenha(userName: string): Observable<User>{


    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
    .get(API_URL + '/requestSenha/' + userName, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new User(response.json());
    })
    .catch(this.handleError);
  }

  public GetReportClient(reportClient: ReportClientRequest): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/reportclient', reportClient, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);

  }

  public atualizaStatusNegocio(statusNegocio: StatusNegocio): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/atualizaStatusNegocio', statusNegocio, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public logCount(logUserRequest: LogUserListRequest): Observable<LogCountResult[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/logCount', logUserRequest, {
        headers: headers
      })
      .map(response => {
        const logCountResult = response.json();
        return logCountResult.map((logCountResult) => new LogCountResult(logCountResult));
      })
      .catch(this.handleError);
  }

  public permissaoDeletarCliente(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/permissionDeleteCliente/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public DeleteCliente(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/dealDeleteClient/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public getAllEmplyeeType(type: string): Observable<Funcionario[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getEmployeeType/' + type, {
        headers: headers
      })
      .map(response => {
        const funcionarios = response.json();
        return funcionarios.map((funcionario) => new Funcionario(funcionario));
      })
      .catch(this.handleError);
  }

  public createClienteHistoryTemp(clienteHistory: ClientHistoryResult): Observable<ClientHistoryResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/createClienteHistoryTemp', clienteHistory, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new ClientHistoryResult(response.json());
      })
      .catch(this.handleError);
  }

  public UpdateClientHistoryTemp(idClientTemp: number, dealId: string): Observable<ClientHistoryResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
    .get(API_URL + '/updateClientHistoryTemp/' + idClientTemp + ", " + dealId, {
      headers: headers
    })
    .map(response => {

      const clienteHistory = response.json();
      return clienteHistory.map((clienteHistory) => new ClientHistoryResult(clienteHistory));
    })
    .catch(this.handleError);
  }

  public GetClientHistoryTemp(clientIdTemp: number): Observable<ClientHistoryResult[]> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getClientHistoryTemp/' + clientIdTemp, {
        headers: headers
      })
      .map(response => {
        const clienteHistory = response.json();
        return clienteHistory.map((clienteHistory) => new ClientHistoryResult(clienteHistory));
      })
      .catch(this.handleError);
  }



  public DeleteLogOnline(employeeId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
    .post(API_URL + '/deleteLogOnline/' + employeeId, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new MensagemCommandResult(response.json());
    })
    .catch(this.handleError);
  }

  public GetUserOnline(): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getUserOnline', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public PermissaoEmployee(code: number): Observable<boolean> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/permissionEmployee/' + code, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        return res !== JSON.stringify({});
      })
      .catch(this.handleError);
  }

  public GetListPermission(): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/GetListPermission', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetListPermissionEmployee(code: number): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/GetListPermissionEmployee/' + code, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public CreatePermissionEmployee(code: number, employeeId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/createPermissionEmployee/' + code + ", " + employeeId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public DeletePermissionEmployee(code: number, employeeId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/deletePermissionEmployee/' + code + ", " + employeeId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public CreateLog(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/createLog/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public createUserLogOnline(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/createUserOnline', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  private PermissaoEmployeeList(employeeId: string): Observable<UniquePermissionCommandResult[]> {

      let headers = new Headers();
      this.createAuthorizationHeader(headers);

      return this.http
      .get(API_URL + '/permissionEmployeeList/' + employeeId, {
        headers: headers
      })
      .pipe(
        map(response => {
          const uniquePermission = response.json();
          return uniquePermission.map((uniquePermission) => new UniquePermissionCommandResult(uniquePermission));
        }),
        catchError(this.handleError)
      );
  }

  private RequestTypeUser(employeeId: string): Observable<EmployeeTypeCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
    .get(API_URL + '/requestTypeUser/' + employeeId, {
      headers: headers
    })
    .map(response => {
      let res = JSON.stringify(response.json());

      if ((res) == JSON.stringify({}))
        return null;
      else
        return new EmployeeTypeCommandResult(response.json());
    })
    .catch(this.handleError);
  }

  private RequestResetPassword(employeeId: string): Observable<MensagemCommandResult>{
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/RequestResetPassword/' + employeeId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetListEmployeeType(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getEmployeeTypeList', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public ImportReport(reportData: GetImportReportCommand[]): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/importReport/', reportData, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetImportReport(): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getReportImport', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetClientImport(cpf: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getClientImport/' + cpf, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetClientDuplicate(cpf: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getClientCpf/' + cpf, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public getLogUsers(data: string): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getLogUsers/' + data, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public getLogUsersMonth(data: string, idEmployee: string): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getLogUsers/' + data + "," + idEmployee, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public getHystoryTransfer(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getHystoryTransfer/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetLogForClient(logUserRequest: LogUserListRequest): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/getLogForClient', logUserRequest, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetLogForClientDetails(logUserRequest: LogUserListRequest): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/getLogForClientDetails', logUserRequest, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetRankingLogAccess(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getRankingLogAccess', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetRankingLogClient(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getRankingLogClient', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetDealBusinessProbability(dealId: string): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getDealBusinessProbability', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public ActivatedDeal(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/activatedDeal/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public UpdateDealClient(dataCustomer: DealDataCustomerCommandRequest): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/updateDeal/', dataCustomer, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public CreateDealClient(dataCustomer: DealDataCustomerCommandRequest): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/CreateDeal/', dataCustomer, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetBirthdays(): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getBirthdays', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetPortifolioFake(dealId: string): Observable<MensagemListCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getPortifolioFake/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemListCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetPortifolio(dealId: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getPortifolio/' + dealId, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetExistsClientImport(cpf: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getExistsClientImport/' + cpf, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public ResetSenhaSistema(id: string): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/resetSenhaSistema/' + id, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public ResetSenhaUsuario(resetSenha: ResetSenhaSistemaCommand): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/resetSenhaUsuario/', resetSenha, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetUser(): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getUser', {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetImportCpfs(reportData: GetImportCpfCommandRequest[]): Observable<MensagemCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .post(API_URL + '/getImportCpfs/', reportData, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new MensagemCommandResult(response.json());
      })
      .catch(this.handleError);
  }

  public GetEmployee(idEmployee: string): Observable<EmployeeCommandResult> {

    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http
      .get(API_URL + '/getEmployee/' + idEmployee, {
        headers: headers
      })
      .map(response => {
        let res = JSON.stringify(response.json());

        if ((res) == JSON.stringify({}))
          return null;
        else
          return new EmployeeCommandResult(response.json());
      })
      .catch(this.handleError);
  }
//#endregion
}
