import { Negocio } from "./negocio";
import { ApiNotificacao } from "./api-notificacao";

export class NegocioResult {
    success: boolean;
    data: Negocio;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
