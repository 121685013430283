import { ApiNotificacao } from "./api-notificacao";
import { Parceiro } from "./parceiro";

export class ParceiroResult {
    success: boolean;
    data: Parceiro;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
