import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { environment } from '../../../../../environments/environment';
import { NegocioDataService } from '../../../../services/negocio-data.service';
import { CustomReuseStrategy } from '../../../../shared/router/custom-reuse-strategy';
import { deal } from '../../../../shared/deal';

const LOGN = environment.login;
const PSWD = environment.password;

@Component({
  selector: 'carrega-usuario-email',
  templateUrl: './carrega-usuario-email.component.html',
  styleUrls: ['./carrega-usuario-email.component.scss']
})

export class NbCarregaUsuarioEmailComponent implements OnInit {
  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected config = {},
    private route: ActivatedRoute,
    protected router: Router,
    private routeReuse: RouteReuseStrategy,
    private negocioDataService: NegocioDataService,
  ) {
    this.provider = this.getConfigValue('forms.login.provider');
   }

  errors: string[] = [];
  messages: string[] = [];
  submitted: boolean = false;
  provider: string = '';
  userToken: any = {};
  user: any = {};
  redirectDelay: number = 0;

  get idDeal(): string {
    return this.route.snapshot.paramMap.get('idDeal');
  }

  ngOnInit() {
    this.userToken.email = LOGN;
    this.userToken.password = PSWD;

    this.loginToken(this.userToken);
  }

  loginToken(user: any): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.provider, user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.messages = result.getMessages();
        this.logout();
        this.redicionarTela();
      } else {
        this.errors = result.getErrors();
      }
    });
  }

  login(user: any): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.provider, user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.router.navigate([`/pages/clientes/detalhe/${this.idDeal}`]);
      } else {
        this.logout();
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

  logout(): void {
    this.service.logout(this.provider).subscribe((result: NbAuthResult) => {
      (<CustomReuseStrategy>this.routeReuse).clearHandlers();
      const redirect = result.getRedirect();
       if (redirect) {
         setTimeout(() => {
         }, this.redirectDelay);
       }
    });
  }

  redicionarTela() {
    const result = this.negocioDataService.GetDealsIds(this.idDeal);

    if (result){
      result.subscribe(dados => {
        const resultUser = this.negocioDataService.GetAccessEmployeeUser(dados.employee_Id);

        if (resultUser){
          resultUser.subscribe(dadosUser => {
            dadosUser = dadosUser;
            this.user.email = dadosUser.username;
            this.user.password = dadosUser.password;

            this.login(this.user);
          });
        }
      });
    }
  }
}
