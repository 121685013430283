export class UniquePermissionCommandResult {
  id: string;
  code: number;
  description: string;

  constructor(values: {
    id: string,
    code: number,
    description: string
  }){
    this.id = values.id;
    this.code = values.code;
    this.description = values.description;
  }
}
