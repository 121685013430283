import { LogUser } from "./logUser";

export class LogUserResult {
   logUsers: LogUser[] = [];
   msgRetorno: string;
   msgErro: string;

  constructor(values: { logUsers: LogUser[], msgRetorno: string, msgErro: string; }) {

    this.logUsers = values.logUsers;
    this.msgRetorno = values.msgRetorno;
    this.msgErro = values.msgErro;
  }
}
