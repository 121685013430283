import { DefaultData } from "./default-data";

export class PagamentoColaborador {
    id: string;
    date: string
    referenceDate: string
    company: DefaultData
    unit: DefaultData
    employee: DefaultData
    fixedValue: number
    transportationVoucher: number
    mealTicket: number
    variableValue: number
    otherValueType: number
    otherValue: number
    totalValue: number

    constructor(values: {
        id: string;
        date: string
        referenceDate: string
        company: DefaultData
        unit: DefaultData
        employee: DefaultData
        fixedValue: number
        transportationVoucher: number
        mealTicket: number
        variableValue: number
        otherValueType: number
        otherValue: number
        totalValue: number
    }) {
        this.id = values.id;
        this.date = values.date;
        this.referenceDate = values.referenceDate;
        this.company = values.company;
        this.unit = values.unit;
        this.employee = values.employee;
        this.fixedValue = values.fixedValue
        this.transportationVoucher = values.transportationVoucher;
        this.mealTicket = values.mealTicket;
        this.variableValue = values.variableValue;
        this.otherValueType = values.otherValueType;
        this.otherValue = values.otherValue;
        this.totalValue = values.totalValue;


    }
}