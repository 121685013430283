export class AccessPermissions {
  id: string;
  employee_id: string;
  usuario: string;
  accessProfile_id: string;
  user_Profile: string;
  accessMenu_id: string;
  menu: string;
  accessScreen_id: string;
  screen: string;
  screen_consult: string;
  screen_edit: string;
  screen_insert: string;
  screen_delete: string;
  employeeLog_id: string;
  usuarioLog: string;
  msgRetorno: string;
  msgErro: string;

  constructor(values: {  id: string, employee_id: string, usuario: string, accessProfile_id: string, user_Profile: string, accessMenu_id: string, menu: string, accessScreen_id: string, screen: string, screen_consult: string, screen_edit: string, screen_insert: string, screen_delete: string, employeeLog_id: string, usuarioLog: string, msgRetorno: string, msgErro: string }) {

      this.id = values.id
      this.employee_id = values.employee_id
      this.usuario = values.usuario
      this.accessProfile_id = values.accessProfile_id
      this.user_Profile = values.user_Profile
      this.accessMenu_id = values.accessMenu_id
      this.menu = values.menu
      this.accessScreen_id = values.accessScreen_id
      this.screen = values.screen
      this.screen_consult = values.screen_consult
      this.screen_edit = values.screen_edit
      this.screen_insert = values.screen_insert
      this.screen_delete = values.screen_delete
      this.employeeLog_id = values.employeeLog_id
      this.usuarioLog = values.usuarioLog
      this.msgRetorno = values.msgRetorno
      this.msgErro = values.msgErro

    }
}
