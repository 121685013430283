export class NegocioLista {

    id: string;
    name: string;
    employeeName: string;
    document: string;
    city: string;
    state: string;
    clientSituation: string;
    priority: string;
    contacted: string;
    situation: string;
    origin: string;
    offerPercentage: string;
    advisoryPercentage: string;
    totalPricePortfolio: number;
    totalPriceAdvisory: number;
    confirmationDate: string;
    employeeLegalDate: string;
    createDate: string;

    originDescription: string;
    contactedDescription: string;
    documentDescription: string;
    priorityDescription: string;
    clientSituationDescription: string;
    situationDescription: string;

    employeeLegalDays: number;
    lastContactDate: string;
    lastReturnDate: string;
    lastReturnHour: string;
    lastReturnDateOthers: string;
    lastTotalAmount: string;
    lastOfferedAmount: string;
    lastFeedback: string;
    numDaysToReturn: number;
    totalOferta: number;

    constructor(values: {
        id: string
        , name: string
        , employeeName: string
        , document: string
        , city: string
        , state: string
        , clientSituation: string
        , priority: string
        , contacted: string
        , situation: string
        , origin: string
        , offerPercentage: string
        , advisoryPercentage: string
        , totalPricePortfolio: number
        , totalPriceAdvisory: number
        , confirmationDate: string
        , employeeLegalDate: string
        , createDate: string
        , lastContactDate: string
        , lastReturnDate: string
        , lastReturnDateOthers: string
        , lastTotalAmount: string
        , lastOfferedAmount: string
        , lastFeedback: string
        , numDaysToReturn: number
        , totalOferta: number
    }) {

        this.id = values.id
        this.name = values.name;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.city = values.city;
        this.state = values.state;
        this.clientSituation = values.clientSituation;
        this.priority = values.priority;
        this.contacted = values.contacted;
        this.situation = values.situation;
        this.origin = values.origin;
        this.offerPercentage = values.offerPercentage;
        this.advisoryPercentage = values.advisoryPercentage;
        this.totalPricePortfolio = values.totalPricePortfolio;
        this.totalPriceAdvisory = values.totalPriceAdvisory;
        this.confirmationDate = values.confirmationDate;
        this.employeeLegalDate = values.employeeLegalDate;
        this.createDate = values.createDate;
        this.lastContactDate = values.lastContactDate;
        this.lastReturnDate = values.lastReturnDate;
        this.lastReturnDateOthers = values.lastReturnDateOthers;
        this.lastTotalAmount = values.lastTotalAmount;
        this.lastOfferedAmount = values.lastOfferedAmount;
        this.lastFeedback = values.lastFeedback;
        this.numDaysToReturn = values.numDaysToReturn;
        this.totalOferta = values.totalOferta;

        if (this.employeeLegalDate) {
            var dtSend = new Date(this.employeeLegalDate);
            var today = new Date();
            this.employeeLegalDays = Math.ceil(Math.abs(today.getTime() - dtSend.getTime()) / (1000 * 3600 * 24)) - 1;
        }

        if (!this.origin)
            this.origin = '0';

        switch (this.origin.toString()) {
            case '1':
                this.originDescription = 'Lista não quis';
                break;
            case '2':
                this.originDescription = 'Sem Contato';
                break;
            case '3':
                this.originDescription = 'D ITAU-UNIBANCO';
                break;
            case '4':
                this.originDescription = 'D BRADESCO';
                break;
            case '5':
                this.originDescription = 'D BANCO DO BRASIL';
                break;
            case '6':
                this.originDescription = 'D ITAUSA';
                break;
            case '7':
                this.originDescription = 'D SANTANDER';
                break;
            case '8':
                this.originDescription = 'D PETRO';
                break;
            case '9':
                this.originDescription = 'D AMBEV';
                break;
            case '10':
                this.originDescription = 'Escritório SP';
                break;
            case '11':
                this.originDescription = 'Lista Santander';
                break;
            default:
                this.clientSituationDescription = 'Não Definido';
                break;
        };


        if (this.contacted) {
            this.contactedDescription = 'Sim';
        }
        else {
            this.contactedDescription = 'Não';
        }

        this.documentDescription = this.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");

        switch (this.priority.toString()) {
            case '0':
                this.priorityDescription = 'Urgente';
                break;
            case '1':
                this.priorityDescription = 'Alta';
                break;
            case '2':
                this.priorityDescription = 'Média';
                break;
            case '3':
                this.priorityDescription = 'Baixa';
                break;
            default:
                this.priorityDescription = 'Não Definido';
                break;
        };

        if (!this.clientSituation)
            this.clientSituation = '0';

        switch (this.clientSituation.toString()) {
            case '1':
                this.clientSituationDescription = 'Regular';
                break;
            case '2':
                this.clientSituationDescription = 'Suspenso';
                break;
            case '3':
                this.clientSituationDescription = 'Espólio';
                break;
            case '4':
                this.clientSituationDescription = 'Cancelado';
                break;
            case '5':
                this.clientSituationDescription = 'Pendente de regularização';
                break;
            default:
                this.clientSituationDescription = 'Não Definido';
                break;
        };

        if (!this.situation)
            this.situation = '0';

        switch (this.situation.toString()) {
            case '0':
              this.situationDescription = '';
              break;
            case '3':
              this.situationDescription =	'Assessoria';
              break;
            case '19':
              this.situationDescription = 'Com impedimento / Não Compensa';
              break;
            case '10':
              this.situationDescription = 'Desistiu';
              break;
            case '14':
              this.situationDescription = 'Discutindo contrato/ %';
              break;
            case '13':
              this.situationDescription = 'Em contato com terceiros';
              break;
            case '2':
              this.situationDescription = 'Em contato direto';
              break;
            case '1':
              this.situationDescription = 'Em Negociação';
              break;
            case '18':
              this.situationDescription = 'Improvável contratação / Contatar futuramente';
              break;
            case '6':
              this.situationDescription = 'Já compramos / Assessoramos';
              break;
            case '20':
              this.situationDescription = 'Já Vendeu / Sem interesse';
              break;
            case '4':
              	this.situationDescription = 'Não Possui Ações';
                break;
            case '17':
              this.situationDescription = 'Pesquisando / Sem Contato';
              break;
            default:
                this.situationDescription = 'Não Definido';
                break;
        };
    }
}
