import { DespesaNegocio } from "./despesa-negocio";
import { HerdeiroCliente } from "./herdeiro-cliente";
import { PapelCliente } from "./papel-cliente";
import { Parceiro } from "./parceiro";
import { Processo } from "./processo";
import { TelefoneCliente } from "./telefone-cliente";
import Utils from "./utils";

export class NegocioRealizado {
    id: string;
    company: string;
    unit: string;
    status: string;
    type: string;
    modality: string;
    transferedTo: string;
    date: string;
    sendDate: string;
    transferDate: string;
    employee: string;
    employeeName: string;
    corresponding: string;
    correspondingName: string;
    responsible: string;
    responsibleName: string;
    client: string;
    clientName: string;
    document: string;
    birthdate: string;
    city: string;
    state: string;
    responsibleOffice: string;
    inventoryOffice: string;
    migrated: boolean;

    totalPricePortfolio: number;
    totalDividend: number;
    total: number;
    amountPaid: number;
    totalSellPrice: number;
    percentageContract: number;
    totalContract: number;
    percentagePaid: number;
    grossProfit: number;

    brokerCommission: number;
    correspondingCommission: number;
    partnerPercentage: number;
    partnerCommission: number;
    totalComissoes: number;

    netProfit: number;
    percentageGainBefore: number;

    taxPercentage: number;
    taxAmount: number;
    profit: number;

    percentageGain: number;
    percentageProfit: number;


    processes: Processo[] = [];
    partners: Parceiro[] = [];
    phones: TelefoneCliente[] = [];
    portfolio: PapelCliente[] = [];
    expenses: DespesaNegocio[] = [];
    heirs: HerdeiroCliente[] = [];

    totalExpenses: number;
    clientAge: string;
    sendDays: number;
    processDays: number;

    constructor(values: {
        id: string
        , company: string
        , unit: string
        , status: string
        , type: string
        , modality: string
        , transferedTo: string
        , date: string
        , sendDate: string
        , transferDate: string
        , employee: string
        , employeeName: string
        , corresponding: string
        , correspondingName: string
        , responsible: string
        , responsibleName: string
        , client: string
        , clientName: string
        , document: string
        , birthdate: string
        , city: string
        , state: string
        , responsibleOffice: string
        , inventoryOffice: string
        , migrated: boolean
        , totalPricePortfolio: number
        , totalDividend: number
        , amountPaid: number
        , totalSellPrice: number
        , percentageContract: number
        , grossProfit: number
        , brokerCommission: number
        , correspondingCommission: number
        , partnerPercentage: number
        , taxPercentage: number
        , processes: Processo[]
        , partners: Parceiro[]
        , phones: TelefoneCliente[]
        , portfolio: PapelCliente[]
        , expenses: DespesaNegocio[]
        , heirs: HerdeiroCliente[]
    }) {

        this.id = values.id
        this.company = values.company;
        this.unit = values.unit;
        this.status = values.status;
        this.type = values.type;
        this.modality = values.modality;
        this.transferedTo = values.transferedTo;
        this.date = values.date;
        this.sendDate = values.sendDate;
        this.transferDate = values.transferDate;
        this.corresponding = values.corresponding;
        this.correspondingName = values.correspondingName;
        this.responsible = values.responsible;
        this.responsibleName = values.responsibleName;
        this.employee = values.employee;
        this.employeeName = values.employeeName;
        this.client = values.client;
        this.clientName = values.clientName;
        this.document = values.document;
        this.birthdate = values.birthdate;
        this.city = values.city;
        this.state = values.state;
        this.responsibleOffice = values.responsibleOffice;
        this.inventoryOffice = values.inventoryOffice;
        this.migrated = values.migrated;


        this.processes = values.processes;
        this.partners = values.partners;
        this.phones = values.phones;
        this.portfolio = values.portfolio;
        this.expenses = values.expenses;
        this.heirs = values.heirs;

        this.partners.forEach(value => {
            if (value.type == '2') {
                this.corresponding = value.id;
                this.correspondingName = value.name;
            } else if (value.type == '4') {
                this.responsible = value.id;
                this.responsibleName = value.name;
            }
        });

        if (!this.corresponding) {
            this.corresponding = Utils.GUIDEmpty;
            this.correspondingName = 'NENHUM';
        }

        if (!this.responsible) {
            this.responsible = Utils.GUIDEmpty;
            this.responsibleName = 'NENHUM';
        }

        this.totalPricePortfolio = values.totalPricePortfolio;
        if (!this.totalPricePortfolio) {
            this.totalPricePortfolio = 0;
        }

        this.totalDividend = values.totalDividend;
        if (!this.totalDividend) {
            this.totalDividend = 0;
        }

        this.amountPaid = values.amountPaid;
        if (!this.amountPaid) {
            this.amountPaid = 0;
        }

        this.percentageContract = values.percentageContract;
        if (!this.percentageContract) {
            this.percentageContract = 0;
        }

        this.totalSellPrice = values.totalSellPrice;
        if (!this.totalSellPrice) {
            this.totalSellPrice = 0;
        }

        this.grossProfit = values.grossProfit;
        if (!this.grossProfit) {
            this.grossProfit = 0;
        }

        this.percentagePaid = 0;

        this.partnerPercentage = values.partnerPercentage;
        if (!this.partnerPercentage) {
            this.partnerPercentage = 0;
        }

        this.taxPercentage = values.taxPercentage;
        if (!this.taxPercentage) {
            this.taxPercentage = 0;
        }

        this.brokerCommission = values.brokerCommission;
        if (!this.brokerCommission) {
            this.brokerCommission = 0;
        }

        this.correspondingCommission = values.correspondingCommission;
        if (!this.correspondingCommission) {
            this.correspondingCommission = 0;
        }

        //CÁLCULOS
        this.total = this.totalPricePortfolio + this.totalDividend;

        if (this.type == "1") {
            if (this.percentageContract > 0 && this.total > 0) {
                this.totalContract = this.total * (this.percentageContract / 100);
            } else {
                this.totalContract = 0;
            }

            if (this.grossProfit == 0) {
                this.grossProfit = this.totalContract;
            }
        } else if (this.total > 0) {
            this.grossProfit = this.total - this.amountPaid;
        } else {
            this.grossProfit = 0;
        }

        if (this.partnerPercentage > 0) {
            this.partnerCommission = this.totalPricePortfolio * (this.partnerPercentage / 100);
        } else {
            this.partnerCommission = 0;
        }

        this.totalExpenses = 0;
        this.expenses.forEach(value => {
            this.totalExpenses += value.amount;
        });

        this.totalComissoes = this.brokerCommission + this.correspondingCommission + this.partnerCommission;
        this.netProfit = this.grossProfit - this.totalExpenses - this.totalComissoes;

        this.taxAmount = this.netProfit * this.taxPercentage;
        this.profit = this.netProfit - this.taxAmount;
        this.percentageGainBefore = 0;
        this.percentageGain = 0;
        this.percentageProfit = 0;
    }
}