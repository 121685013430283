export class Permissao {

    authorized: string;
    readOnly: boolean;
    owner: string;
    deal: string;
    employees: any[] = [];

    constructor(values: {
        authorized: string,
        readOnly: boolean,
        owner: string,
        deal: string,
        employees: any[]
    }) {
        this.authorized = values.authorized
        this.readOnly = values.readOnly
        this.owner = values.owner;
        this.deal = values.deal;
        this.employees = values.employees;
    }
}