import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { ContatoCliente } from '../shared/contato-cliente';
import { Papel } from '../shared/papel';
import { PapelCliente } from '../shared/papel-cliente';
import { Parceiro } from '../shared/parceiro';
import { Funcionario } from '../shared/funcionario';

@Injectable({
  providedIn: 'root'
})
export class ApiMockService {

  constructor() { }

  public getAllContatosCliente(): Observable<ContatoCliente[]> {
    return Observable.of([
      new ContatoCliente({id: 1, contactDate: '01/01/1901', returnDate: '01/01/1901', employeeName: 'Nicolas Moreira', totalAmount: '555,00', amountOffered: '400,00', feedback: 'Ficou agendada uma visita no dia 01/01/1901 às 15:00 na residência do cliente.'}),
      new ContatoCliente({id: 2, contactDate: '01/01/1901', returnDate: '01/01/1901', employeeName: 'Regina Marques', totalAmount: '0,00', amountOffered: '0,00', feedback: 'Primeira tentativa de contato sem sucesso.'})
    ]);
  }

  // public getAllPapeisCliente(): Observable<PapelCliente[]> {
  //   return Observable.of([
  //     new PapelCliente({id: '1', code: 'SANB4', price: 0, volume: 1500, discount: 0, finalPrice: 0}),
  //     new PapelCliente({id: '2', code: 'ITUB4.SA', price: 0, volume: 700, discount: 0, finalPrice: 0}),
  //   ]);
  // }

  public getAllPapeis(): Observable<Papel[]> {
    return Observable.of([
      new Papel({id: '1', code: 'SANB4', price: 0}),
      new Papel({id: '2', code: 'ITUB4.SA', price: 0}),
    ]);
  }

  public getAllCorretores(): Observable<Funcionario[]> {
    return Observable.of([
      new Funcionario({ id: '8fd3135d-1c66-4a9e-b697-bcbbcbfdae55', document: '85149884090', firstName:'', middleName:'', lastName:'', name: 'Helena Santos', email: '', type:'2', responsibleAnalyst: true}),
      new Funcionario({ id: 'e9fe98ba-d7c2-4ea8-a6e8-ecb29b915b62', document: '09535462091', firstName:'', middleName:'', lastName:'', name: 'Bernardo Ferreira', email: '', type:'2', responsibleAnalyst: false}),
    ]);
  }

  public getAllInvestidores(): Observable<Parceiro[]> {
    return Observable.of([
      new Parceiro({ id: '8fd3135d-1c66-4a9e-b697-bcbbcbfdae55', document: '85149884090', firstName:'', middleName:'', lastName:'', name: 'Alice Silva', type:'1'}),
      new Parceiro({ id: 'e9fe98ba-d7c2-4ea8-a6e8-ecb29b915b62', document: '09535462091', firstName:'', middleName:'', lastName:'', name: 'Miguel Oliveira', type:'1'}),
    ]);
  }

  public getAllCorrespondentes(): Observable<Parceiro[]> {
    return Observable.of([
      new Parceiro({ id: '8fd3135d-1c66-4a9e-b697-bcbbcbfdae55', document: '67012687061', firstName:'', middleName:'', lastName:'', name: 'Arthur Souza', type:'2'}),
      new Parceiro({ id: 'e9fe98ba-d7c2-4ea8-a6e8-ecb29b915b62', document: '31163243019', firstName:'', middleName:'', lastName:'', name: 'Sophia Rodigues', type:'2'}),
    ]);
  }
}
