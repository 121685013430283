import { PapelAcionista } from "./papel-acionista";

export class Acionista {
    id: string;
    document: string;
    name: string;
    portfolio: PapelAcionista[] = [];
    
    totalPricePortfolio: number;

    constructor(values: { id: string, document: string, name: string, portfolio: PapelAcionista[] }) {

        this.id = values.id;
        this.document = values.document;
        this.name = values.name;
        this.portfolio = values.portfolio;
        this.totalPricePortfolio = 0;
        this.portfolio.forEach(value => {
            this.totalPricePortfolio += value.totalPrice;
        });
    }
}