import { throwToolbarMixedModesError } from "@angular/material";

export class ClientHistoryResult {
    id: string;
    client_id: string;
    history: string;
    created_date: string;
    changed_date: string;
    employeeLog_id: string;
    msgErro: string;
    dealId: string;
    msgRetorno: string;
    nameEmployee: string;
    idClientTemp: number;

    constructor(values: { id: string,
      client_id: string,
      history: string,
      created_date: string,
      changed_date: string,
      employeeLog_id: string,
      msgErro: string,
      dealId: string,
      msgRetorno: string,
      nameEmployee: string,
      idClientTemp: number}) {

      this.id = values.id;
      this.client_id = values.client_id;
      this.history = values.history;
      this.created_date = values.created_date;
      this.changed_date = values.changed_date;
      this.employeeLog_id = values.employeeLog_id;
      this.msgErro = values.msgErro;
      this.dealId = values.dealId;
      this.msgRetorno = values.msgRetorno;
      this.nameEmployee = values.nameEmployee;
      this.idClientTemp = values.idClientTemp;
    }
}
