export class Funcionario {
    id: string;
    deal: string;
    document: string;
    name: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    type: string;

    typeDescription: string;
    responsibleAnalyst: boolean;

    constructor(values: { id: string, document: string, firstName: string, middleName: string, lastName: string, name: string, email: string, type: string, responsibleAnalyst: boolean }) {
        this.id = values.id;
        this.document = values.document;
        this.name = values.name;
        this.firstName = values.firstName;
        this.middleName = values.middleName;
        this.lastName = values.lastName;
        this.email = values.email;
        this.responsibleAnalyst = values.responsibleAnalyst;

        if (!this.type)
            this.type = '';

        switch (this.type.toString()) {
            case '1':
                this.typeDescription = 'Comum';
                break;
            case '2':
                this.typeDescription = 'Analista';
                break;
            case '3':
                this.typeDescription = 'Administrador';
                break;
            default:
                this.typeDescription = 'Não Definido';
                break;
        };
    }
}