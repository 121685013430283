export class AccessPermissionsPerfil {
    perfil: string;
    retorno: string;

    constructor(values: {  perfil: string, retorno: string}) {

      this.perfil = values.perfil
      this.retorno = values.retorno
    }
}
