export class AccessScreen {
    id: string;
    menu_id: string;
    menu: string;
    screen: string;
    consultar: boolean;
    editar: boolean;
    inserir: boolean;
    deletar: boolean;

    constructor(values: { id: string, menu_id: string, menu: string, screen: string, consultar: boolean, editar: boolean, inserir: boolean, deletar: boolean}) {

        this.id = values.id;
        this.menu_id = values.menu_id;
        this.menu = values.menu;
        this.screen = values.screen;
        this.consultar = values.consultar;
        this.editar = values.editar;
        this.inserir = values.inserir;
        this.deletar = values.deletar;
    }
}
