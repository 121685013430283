export class NegocioRealizadoLista {

    id: string;
    name: string;
    employeeName: string;
    document: string;
    city: string;
    state: string;
    unit: string;
    status: string;
    date: string;
    sendDate: string;
    transferDate: string;
    totalPricePortfolio: number;
    totalDividend: number;
    amountPaid: number;
    total: number;
    grossProfit: number;
    totalExpenses: number;
    totalComissoes: number;
    netProfit: number;
    percentageGainBefore: number;
    taxPercentage: number;
    taxAmount: number;
    profit: number;
    percentageGain: number;
    percentageProfit: number;
    documentDescription: string;
    unitDescription: string;
    statusDescription: string;

    constructor(values: {
        id: string,
        name: string,
        employeeName: string,
        document: string,
        city: string,
        state: string,
        unit: string,
        status: string,
        date: string,
        sendDate: string,
        transferDate: string,
        totalPricePortfolio: number,
        totalDividend: number,
        amountPaid: number,
        total: number,
        grossProfit: number,
        totalExpenses: number,
        totalComissoes: number,
        netProfit: number,
        percentageGainBefore: number,
        taxPercentage: number,
        taxAmount: number,
        profit: number,
        percentageGain: number,
        percentageProfit: number
    }) {

        this.id = values.id
        this.name = values.name;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.city = values.city;
        this.state = values.state;
        this.unit = values.unit;
        this.status = values.status;
        this.date = values.date;
        this.sendDate = values.sendDate;
        this.transferDate = values.transferDate;
        this.totalPricePortfolio = values.totalPricePortfolio;
        this.totalDividend = values.totalDividend;
        this.amountPaid = values.amountPaid;
        this.total = values.total;
        this.grossProfit = values.grossProfit;
        this.totalExpenses = values.totalExpenses;
        this.totalComissoes = values.totalComissoes;
        this.netProfit = values.netProfit;
        this.percentageGainBefore = values.percentageGainBefore;
        this.taxPercentage = values.taxPercentage;
        this.taxAmount = values.taxAmount;
        this.profit = values.profit;
        this.percentageGain = values.percentageGain;
        this.percentageProfit = values.percentageProfit;

        this.documentDescription = this.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");

        if (!this.unit)
            this.unit = '0';

        switch (this.unit.toString()) {
            case '0':
                this.unitDescription = 'Goiânia';
                break;
            case '1':
                this.unitDescription = 'São Paulo';
                break;
            default:
                this.unitDescription = 'Não Definido';
                break;
        };

        if (!this.status)
            this.status = '0';

        switch (this.status.toString()) {
            case '0':
                this.statusDescription = 'Parado no GR';
                break;
            case '1':
                this.statusDescription = 'Faltando documentação';
                break;
            case '2':
                this.statusDescription = 'Enviado ao banco';
                break;
            case '3':
                this.statusDescription = 'Transferido';
                break;
            case '4':
                this.statusDescription = 'Custódia da corretora';
                break;
            case '5':
                this.statusDescription = 'Finalizado';
                break;
            case '6':
                this.statusDescription = 'Cliente não confirma';
                break;
            case '7':
                this.statusDescription = 'Providenciando documentação';
                break;
            default:
                this.statusDescription = 'Não Definido';
                break;
        };
    }
}