import { LogCountResult } from './../../../shared/logCountResult';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import {MatIconModule} from '@angular/material/icon';
import { NegocioDataService } from '../../../services/negocio-data.service';
import Utils from '../../../shared/utils';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBirthdayComponent } from '../modal/modal-birthday/modal-birthday.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user = {};

  userMenu = [{ title: 'Sair', link: '/auth/logout' }];

  items = [];
  tooltipOnline: string;

  permissaoCarregada: boolean = false;
  permissaoBotaoOline: boolean = false;

  config: ToasterConfig;
  successType = 'success';

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private authService: NbAuthService,
              private negocioDataService: NegocioDataService,
              private toasterService: ToasterService,
              private modalService: NgbModal) {
                this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
  }

  ngOnInit() {
    setTimeout(() => {
      this.carregarItems();
    }, 180000); // 180000 milissegundos = 3 minutos
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  get habilitarBotaoAniversario(): boolean {
    return this.negocioDataService.getPermissaoUnica(9);
  }

  get habilitarBotaoNeoway(): boolean {
    return this.negocioDataService.getPermissaoUnica(13);
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  carregarItems(){
    this.items = [];

      let textTooltip: string;
      const result = this.negocioDataService.GetUserOnline();

      if (result){
        result.subscribe(dados => {
          if (dados.msgRetorno == "OK" && dados.resultList.length >= 0) {
            textTooltip = "Contém " + dados.resultList.length + " pessoas online";
            dados.resultList.forEach(element => {
              this.items.push({ title: element.employeeName });
            });

            this.tooltipOnline = textTooltip;
          }
        });
      }
  }

  carregaAniversarios(){
    const activeModal = this.modalService.open(ModalBirthdayComponent, { size: 'lg', container: 'nb-layout',  backdrop: 'static' });
  }

  redirectToNeoway() {
    window.open('https://apps.neoway.com.br');
  }
}
