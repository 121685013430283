import { ContatoCliente } from "./contato-cliente";
import { TelefoneCliente } from "./telefone-cliente";
import { EmailCliente } from "./email-cliente";

export class NegocioJuridico {

    id: string;
    employee: string;
    name: string;
    employeeName: string;
    document: string;
    birthdate: string;
    spouse: string;
    clientSituation: string;
    email: string;
    phone: string;
    city: string;
    state: string;
    priority: string;
    contacted: string;
    referral: string;
    createDate: string;
    phones: TelefoneCliente[] = [];
    emails: EmailCliente[] = [];
    contacts: ContatoCliente[] = [];

    constructor(values: {
        id: string,
        name: string,
        employee: string,
        employeeName: string,
        document: string,
        birthdate: string,
        spouse: string,
        clientSituation: string,
        email: string,
        phone: string,
        city: string,
        state: string,
        priority: string,
        contacted: string,
        referral: string,
        createDate: string,
        phones: TelefoneCliente[],
        emails: EmailCliente[],
        contacts: ContatoCliente[]
    }) {
        this.id = values.id
        this.name = values.name;
        this.employee = values.employee;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.birthdate = values.birthdate;
        this.spouse = values.spouse;
        this.clientSituation = values.clientSituation;
        this.email = values.email;
        this.phone = values.phone;
        this.city = values.city;
        this.state = values.state;
        this.priority = values.priority;
        this.contacted = values.contacted;
        this.referral = values.referral;
        this.createDate = values.createDate;
        this.phones = values.phones;
        this.emails = values.emails;
        this.contacts = values.contacts;

        if (!this.referral) {
            this.referral = '0';
        }

        if (!this.clientSituation) {
            this.clientSituation = '0';
        }
    }
}