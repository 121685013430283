import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators/tap';
import { ApiService } from './services/api.service';
import Utils from './shared/utils';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private api: ApiService, private authService: NbAuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const permission = route.data["permission"];
        let resCanActivate: boolean = true;

        if (permission && permission.only && permission.only.length) {
            resCanActivate = false;
            let role = permission.only.includes('admin');
            if (role) {
                resCanActivate = this.api.isAdminUser() && this.api.isGestaoReno();
            }

            if (!resCanActivate) {
                role = permission.only.includes('climan');
                if (role) {
                    resCanActivate = this.api.isClientManagerUser() && this.api.isGestaoReno();
                }
            }

            if (!resCanActivate) {
                role = permission.only.includes('jur');
                if (role) {
                    resCanActivate = this.api.isGestaoRenoJuridico();
                }
            }

            if (!resCanActivate) {
                role = permission.only.includes('greno');
                if (role) {
                    resCanActivate = this.api.isGestaoReno();
                }
            }
        }

        return this.authService.isAuthenticated()
        .pipe(
            tap(authenticated => {
                if (!authenticated) {
                    this.router.navigate(['auth/login']);
                }
                else if (!resCanActivate && this.api.isGestaoReno() ) {
                    // this.router.navigate(['pages/clientes/lista']);
                    this.router.navigate(['auth/cadastrar-senha']);
                }
                else if (!resCanActivate && this.api.isGestaoRenoJuridico()) {
                    this.router.navigate(['pages/clientes-juridico/lista']);
                }
            }),
        );;
    }
}
