import { DatePipe } from '@angular/common';
import { DatepickerViewModel } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model";

export class deal {
  client_Id: string;
  employee_Id: string;

  constructor(values: { client_Id: string, employee_Id: string; }) {
    this.client_Id = values.client_Id;
    this.employee_Id = values.employee_Id;

  }
}
