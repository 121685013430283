import { AnalysisEmployeeCommissionCommandResult } from "./AnalysisEmployeeCommissionCommandResult";
import { BirthdaysCommandResult } from "./BirthdaysCommandResult";
import { ClientCommandResult } from "./ClientCommandResult";
import { CommissionReceivedCommandRequest } from "./CommissionReceivedCommandRequest";
import { CommissionTypeDescriptionCommandResult } from "./CommissionTypeDescriptionCommandResult";
import { DealBusinessProbabilityCommandResult } from "./DealBusinessProbabilityCommandResult";
import { DealBusinessStatusCommandResult } from "./DealBusinessStatusCommandRequest";
import { DealExpenseCommandResult } from "./DealExpenseCommandResult";
import { DealProgressProcessCommandResult } from "./DealProgressProcessCommandResult";
import { DealSituationResult } from "./DealSituationResult";
import { DealUpdateCommandResult } from "./DealUpdateCommandResult";
import { EmployeeHistoryTransferCommandResult } from "./EmployeeHistoryTransferCommandResult";
import { EmployeeLegalHistorySendToCommandResult } from "./EmployeeLegalHistorySendToCommandResult";
import { EmployeeTypeCommandResult } from "./EmployeeTypeCommandResult";
import { ExpenseTypeDescriptionCommandResult } from "./ExpenseTypeDescriptionCommandResult";
import { GetAccessPermissionsCommandResult } from "./GetAccessPermissionsCommandResult";
import { GetRelatorioLogDiarioCommandResult } from "./GetRelatorioLogDiarioCommandResult";
import { GetRelatorioLogMensalCommandResult } from "./GetRelatorioLogMensalCommandResult";
import { LogUserForClientCommandResult } from "./LogUserForClientCommandResult";
import { LogUserForClientDetailsCommandResult } from "./LogUserForClientDetailsCommandResult";
import { PortifolioCommandResult } from "./PortifolioCommandResult";
import { SinglePermissionEmployeeCommandResult } from "./SinglePermissionEmployeeCommandResult";
import { UniquePermissionCommandResult } from "./UniquePermissionCommandResult";
import { ClientImport } from "./clientImport";

export class MensagemListCommandResult {
  msgErro: string;
  msgRetorno: string;
  dealExpense: DealExpenseCommandResult[] = [];
  singlePermissionEmployees: SinglePermissionEmployeeCommandResult[] = [];
  uniquePermission: UniquePermissionCommandResult[] = [];
  employeeTypes: EmployeeTypeCommandResult[] = [];
  clientImport: ClientImport[] = [];
  client: ClientCommandResult[] = [];
  logDiario: GetRelatorioLogDiarioCommandResult[] = [];
  logMensal: GetRelatorioLogMensalCommandResult[] = [];
  employeeHistoryTransfer: EmployeeHistoryTransferCommandResult[] = [];
  logUserForClient: LogUserForClientCommandResult[] = [];
  logUserForClientDetails: LogUserForClientDetailsCommandResult[] = [];
  dealBusinessProbability: DealBusinessProbabilityCommandResult[] = [];
  employeeLegalHistorySendTo: EmployeeLegalHistorySendToCommandResult[] = [];
  commissionTypeDescription: CommissionTypeDescriptionCommandResult[] = [];
  analysisEmployeeCommission: AnalysisEmployeeCommissionCommandResult[] = [];
  expenseType: ExpenseTypeDescriptionCommandResult[] = [];
  dealBusinessStatus: DealBusinessStatusCommandResult[] = [];
  dealProgressProcess: DealProgressProcessCommandResult[] = [];
  dealUpdate: DealUpdateCommandResult[] = [];
  // commissionReceived: CommissionReceivedCommandRequest[] = [];
  commissionReceivedCode: CommissionReceivedCommandRequest[] = [];
  birthdays: BirthdaysCommandResult;
  portifolio: PortifolioCommandResult[] = [];
  dealSituation: DealSituationResult[] = [];
  accessPermissions: GetAccessPermissionsCommandResult[] = [];
  resultList: any[] = [];

  constructor(values: {
    msgErro: string,
    msgRetorno: string,
    dealExpense: DealExpenseCommandResult[],
    singlePermissionEmployees: SinglePermissionEmployeeCommandResult[],
    uniquePermission: UniquePermissionCommandResult[],
    employeeTypes: EmployeeTypeCommandResult[],
    clientImport: ClientImport[],
    client: ClientCommandResult[],
    logDiario: GetRelatorioLogDiarioCommandResult[],
    logMensal: GetRelatorioLogMensalCommandResult[],
    employeeHistoryTransfer: EmployeeHistoryTransferCommandResult[],
    logUserForClient: LogUserForClientCommandResult[],
    logUserForClientDetails: LogUserForClientDetailsCommandResult[],
    dealBusinessProbability: DealBusinessProbabilityCommandResult[],
    employeeLegalHistorySendTo: EmployeeLegalHistorySendToCommandResult[],
    commissionTypeDescription: CommissionTypeDescriptionCommandResult[],
    analysisEmployeeCommission: AnalysisEmployeeCommissionCommandResult[],
    expenseType: ExpenseTypeDescriptionCommandResult[],
    dealBusinessStatus: DealBusinessStatusCommandResult[],
    dealProgressProcess: DealProgressProcessCommandResult[],
    dealUpdate: DealUpdateCommandResult[],
    // commissionReceived: CommissionReceivedCommandRequest[],
    commissionReceivedCode: CommissionReceivedCommandRequest[],
    birthdays: BirthdaysCommandResult,
    portifolio: PortifolioCommandResult[],
    dealSituation: DealSituationResult[],
    accessPermissions: GetAccessPermissionsCommandResult[],
    resultList: any[]
  }){
    this.msgErro = values.msgErro;
    this.msgRetorno = values.msgRetorno;
    this.dealExpense = values.dealExpense;
    this.singlePermissionEmployees = values.singlePermissionEmployees;
    this.uniquePermission = values.uniquePermission;
    this.employeeTypes = values.employeeTypes;
    this.clientImport = values.clientImport;
    this.client = values.client;
    this.logDiario = values.logDiario;
    this.logMensal = values.logMensal;
    this.employeeHistoryTransfer = values.employeeHistoryTransfer;
    this.logUserForClient = values.logUserForClient;
    this.logUserForClientDetails = values.logUserForClientDetails;
    this.dealBusinessProbability = values.dealBusinessProbability;
    this.employeeLegalHistorySendTo = values.employeeLegalHistorySendTo;
    this.commissionTypeDescription = values.commissionTypeDescription;
    this.analysisEmployeeCommission = values.analysisEmployeeCommission;
    this.expenseType = values.expenseType;
    this.dealBusinessStatus = values.dealBusinessStatus;
    this.dealProgressProcess = values.dealProgressProcess;
    this.dealUpdate = values.dealUpdate;
    // this.commissionReceived = values.commissionReceived;
    this.commissionReceivedCode = values.commissionReceivedCode;
    this.birthdays = values.birthdays;
    this.portifolio = values.portifolio;
    this.dealSituation = values.dealSituation;
    this.accessPermissions = values.accessPermissions;
    this.resultList = values.resultList;
  }
}
