export class ContatoCliente {
    forEach(arg0: (value: any) => void) {
      throw new Error('Method not implemented.');
    }

    id: string;
    contactDate: string;
    returnDate: string;
    returnHour: string;
    employee: string;
    employeeName: string;
    totalAmount: string;
    offeredAmount: string;
    feedback: string;
    numDaysToReturn: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
