import { Funcionario } from "./funcionario";

export class FuncionariosCliente {
    isMyClient: boolean;
    document: string;
    name: string;
    employees: Funcionario[] = [];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}