import { ApiNotificacao } from "./api-notificacao";
import { Acionista } from "./acionista";

export class AcionistaResult {
    success: boolean;
    data: Acionista;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
