export class Apontamento {
    id: string;
    description: string;
    hours: string;
    referenceDate: string;
    createdDate: string;
    employeeId: string;
    employeeName: string;
    companyId: string;
    companyName: string;
    


    constructor(values: { id: string, description: string, hours: string, referenceDate: string, createdDate: string, employeeId: string, employeeName: string, companyId: string, companyName: string }) {

        this.id = values.id;
        this.description = values.description;
        this.hours = values.hours;
        this.referenceDate = values.referenceDate;
        this.createdDate = values.createdDate;
        this.employeeId = values.employeeId;
        this.employeeName = values.employeeName;
        this.companyId = values.companyId;
        this.companyName = values.companyName;

        
    }
}