export class NegocioJuridicoLista {

    id: string;
    name: string;
    employeeName: string;
    document: string;
    city: string;
    state: string;
    clientSituation: string;
    priority: string;
    contacted: string;
    origin: string;
    confirmationDate: string;
    createDate: string;

    originDescription: string;
    contactedDescription: string;
    documentDescription: string;
    priorityDescription: string;
    clientSituationDescription: string;

    lastContactDate: string;
    lastReturnDate: string;
    lastFeedback: string;

    constructor(values: {
        id: string
        , name: string
        , employeeName: string
        , document: string
        , city: string
        , state: string
        , clientSituation: string
        , priority: string
        , contacted: string
        , origin: string
        , confirmationDate: string
        , createDate: string
        , lastContactDate: string
        , lastReturnDate: string
        , lastFeedback: string
    }) {

        this.id = values.id
        this.name = values.name;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.city = values.city;
        this.state = values.state;
        this.clientSituation = values.clientSituation;
        this.priority = values.priority;
        this.contacted = values.contacted;
        this.origin = values.origin;
        this.confirmationDate = values.confirmationDate;
        this.createDate = values.createDate;
        this.lastContactDate = values.lastContactDate
        this.lastReturnDate = values.lastReturnDate
        this.lastFeedback = values.lastFeedback;

        if (!this.origin)
            this.origin = '0';

        switch (this.origin.toString()) {
            case '1':
                this.originDescription = 'Lista não quis';
                break;
            case '2':
                this.originDescription = 'Sem Contato';
                break;
            case '3':
                this.originDescription = 'Escritório SP';
                break;
            default:
                this.clientSituationDescription = 'Não Definido';
                break;
        };


        if (this.contacted) {
            this.contactedDescription = 'Sim';
        }
        else {
            this.contactedDescription = 'Não';
        }

        this.documentDescription = this.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");

        switch (this.priority.toString()) {
            case '0':
                this.priorityDescription = 'Urgente';
                break;
            case '1':
                this.priorityDescription = 'Alta';
                break;
            case '2':
                this.priorityDescription = 'Média';
                break;
            case '3':
                this.priorityDescription = 'Baixa';
                break;
            default:
                this.priorityDescription = 'Não Definido';
                break;
        };

        if (!this.clientSituation)
            this.clientSituation = '0';

        switch (this.clientSituation.toString()) {
            case '1':
                this.clientSituationDescription = 'Regular';
                break;
            case '2':
                this.clientSituationDescription = 'Suspenso';
                break;
            case '3':
                this.clientSituationDescription = 'Espólio';
                break;
            case '4':
                this.clientSituationDescription = 'Cancelado';
                break;
            case '5':
                this.clientSituationDescription = 'Pendente de regularização';
                break;
            default:
                this.clientSituationDescription = 'Não Definido';
                break;
        };
    }
}