export class NegocioJuridicoRealizadoLista {

    id: string;
    name: string;
    employeeName: string;
    document: string;
    city: string;
    state: string;
    unit: string;
    status: string;
    type: string;
    date: string;

    documentDescription: string;
    unitDescription: string;
    statusDescription: string;
    typeDescription: string;

    constructor(values: {
        id: string,
        name: string,
        employeeName: string,
        document: string,
        city: string,
        state: string,
        unit: string,
        status: string,
        type: string;
        date: string
    }) {

        this.id = values.id
        this.name = values.name;
        this.employeeName = values.employeeName;
        this.document = values.document;
        this.city = values.city;
        this.state = values.state;
        this.unit = values.unit;
        this.status = values.status;
        this.type = values.type;
        this.date = values.date;

        this.documentDescription = this.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");

        if (!this.unit)
            this.unit = '0';

        switch (this.unit.toString()) {
            case '0':
                this.unitDescription = 'Goiânia';
                break;
            case '1':
                this.unitDescription = 'São Paulo';
                break;
            default:
                this.unitDescription = 'Não Definido';
                break;
        };

        if (!this.status)
            this.status = '0';

        switch (this.status.toString()) {
            case '0':
                this.statusDescription = 'Faltando documentação';
                break;
            case '1':
                this.statusDescription = 'Finalizado';
                break;
            case '2':
                this.statusDescription = 'Cliente não confirma';
                break;
            case '3':
                this.statusDescription = 'Análise';
                break;
            case '4':
                this.statusDescription = 'Em andamento';
                break;
            case '5':
                this.statusDescription = 'Protocolo';
                break;
            case '6':
                this.statusDescription = 'Deferido';
                break;
            case '7':
                this.statusDescription = 'Indeferido';
                break;
            default:
                this.statusDescription = 'Não Definido';
                break;
        };

        if (!this.type)
            this.type = '0';

        switch (this.type.toString()) {
            case '0':
                this.typeDescription = 'Pessoa Física';
                break;
            case '6':
                this.typeDescription = 'Pessoa Jurídica';
                break;
            case '1':
                this.typeDescription = 'Espólio';
                break;
            case '2':
                this.typeDescription = 'Assessoria';
                break;
            case '3':
                this.typeDescription = 'Compra e venda';
                break;
            case '4':
                this.typeDescription = 'Intermediação';
                break;
            case '5':
                this.typeDescription = 'Documentação';
                break;
            default:
                this.typeDescription = 'Não Definido';
                break;
        };
    }
}