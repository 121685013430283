import { TelefoneCliente } from "./telefone-cliente";
import { Parceiro } from "./parceiro";

export class NegocioJuridicoRealizado {
    id: string;
    unit: string;
    status: string;
    type: string;
    date: string;
    employee: string;
    employeeName: string;
    kind: string
    area: string
    registrationNumber: string
    office: string
    sector: string
    cityPropertie: string
    statePropertie: string
    client: string;
    clientName: string;
    document: string;
    birthdate: string;
    city: string;
    state: string;
    number: string;
    subject: string;
    responsible: string;
    responsibleBody: string;
    deadline: string;
    dilation: string;
    overrun: string;

    partners: Parceiro[] = [];
    phones: TelefoneCliente[] = [];

    clientAge: string;

    constructor(values: {
        id: string
        , unit: string
        , status: string
        , type: string
        , date: string
        , employee: string
        , employeeName: string
        , kind: string
        , area: string
        , registrationNumber: string
        , office: string
        , sector: string
        , cityPropertie: string
        , statePropertie: string
        , client: string
        , clientName: string
        , document: string
        , birthdate: string
        , city: string
        , state: string
        , number: string
        , subject: string
        , responsible: string
        , responsibleBody: string
        , deadline: string
        , dilation: string
        , overrun: string
        , partners: Parceiro[]
        , phones: TelefoneCliente[]
    }) {

        this.id = values.id
        this.unit = values.unit;
        this.status = values.status;
        this.type = values.type;
        this.date = values.date;
        this.employee = values.employee;
        this.employeeName = values.employeeName;
        this.kind = values.kind;
        this.area = values.area;
        this.registrationNumber = values.registrationNumber;
        this.office = values.office;
        this.sector = values.sector;
        this.cityPropertie = values.cityPropertie;
        this.statePropertie = values.statePropertie;
        this.client = values.client;
        this.clientName = values.clientName;
        this.document = values.document;
        this.birthdate = values.birthdate;
        this.city = values.city;
        this.state = values.state;
        this.number = values.number;
        this.subject = values.subject;
        this.responsible = values.responsible;
        this.responsibleBody = values.responsibleBody;
        this.deadline = values.deadline;
        this.dilation = values.dilation;
        this.overrun = values.overrun;

        this.partners = values.partners;
        this.phones = values.phones;
    }
}