import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from './@theme/components/auth';
import { NbCarregaUsuarioEmailComponent } from './@theme/components/auth/carrega-usuario-email/carrega-usuario-email.component';
import { CadastrarSenhaComponent } from './@theme/components/auth/cadastrar-senha/cadastrar-senha.component';

const routes: Routes = [
  { path: 'pages',
    canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
    loadChildren: 'app/pages/pages.module#PagesModule' },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'login',
        component: NbLoginComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'register',
        component: NbRegisterComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
        data: { shouldReuse: false },
      },
      {
        path: 'carrega-usuario-email/:idDeal',
        component: NbCarregaUsuarioEmailComponent,
        data: { shouldReuse: false }
      },
      {
        path: 'cadastrar-senha/:senha',
        component: CadastrarSenhaComponent,
        data: { shouldResue: false }
      }
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full', data: { shouldReuse: false }},
  { path: '**', redirectTo: 'pages', data: { shouldReuse: false }},
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
