import { Injectable } from "@angular/core";
import { ConnectionBackend, Http, RequestOptions, RequestOptionsArgs, Headers, Response, Request, XHRBackend } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class HttpCustomClient extends Http {
  constructor(protected _backend: XHRBackend, protected _defaultOptions: RequestOptions, private _api: ApiService) {
    super(_backend, _defaultOptions);
  }

  customParams?:  {
    [key: string]: any ;
} | null;

  setCustomParams(_customParams:  {
    [key: string]: any ;
} | null) {
        this.customParams = _customParams;
  }

  _setCustomHeaders(options?: RequestOptionsArgs):RequestOptionsArgs{
    if(!options) {
      options = new RequestOptions({});
    }
    //if(localStorage.getItem("id_token")) {

      if (!options.headers) {

        options.headers = new Headers();
      }
      //options.headers.set("Authorization", localStorage.getItem("id_token"))
      this._api.createAuthorizationHeader(options.headers);
    //}
    return options;
  }


  request(url: string|Request, options?: RequestOptionsArgs): Observable<Response> {
    options = this._setCustomHeaders(options);
    return super.request(url, options)
  }

  get(url: string|Request, options?: RequestOptionsArgs): Observable<Response> {
    let headers = new Headers();
    //this.createAuthorizationHeader(headers);
    var urltest = url.toString();
    options = this._setCustomHeaders(options);
    if(this.customParams!=null && this.customParams.toString().length>0) {
      //Object.assign((options.params as URLSearchParams), (this.customParams as URLSearchParams));
      
      //var newoption = options.params.toString() + '&' + (this.customParams as URLSearchParams).toString();
      //options.params = newoption;
      for(var i in this.customParams) {
        (options.params as URLSearchParams).append(i,this.customParams[i]);
      }
      
    }
    return super.get(url.toString(), {
      headers: options.headers,
      params: options.params
    });
  }
  
}