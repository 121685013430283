export class User {
    id: string;
    username: string;
    password: string;
    active: string;
    document: string;
    name: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    email: string;
    responsibleAnalyst: string;
    created_date: string;
    changed_date: string;
    employeeLog_id: string;
    changePassword: string;
    msg: string;
    id_employeeType: string;
    requestPassword: string;

    constructor(values: {
      id: string,
      username: string,
      password: string,
      active: string,
      document: string,
      name: string,
      firstName: string,
      middleName: string,
      lastName: string,
      birthDate: string,
      email: string,
      created_date: string,
      changed_date: string,
      employeeLog_id: string,
      changePassword: string,
      msg: string,
      responsibleAnalyst: string,
      id_employeeType: string,
      requestPassword: string }) {

        this.id = values.id;
        this.username = values.username;
        this.password = values.password;
        this.active = values.active;
        this.document = values.document;
        this.name = values.name;
        this.firstName = values.firstName;
        this.middleName = values.middleName;
        this.lastName = values.lastName;
        this.birthDate = values.birthDate;
        this.email = values.email;
        this.responsibleAnalyst = values.responsibleAnalyst;
        this.created_date = values.created_date;
        this.changed_date = values.changed_date;
        this.employeeLog_id = values.employeeLog_id;
        this.changePassword = values.changePassword;
        this.msg = values.msg;
        this.id_employeeType = values.id_employeeType;
        this.requestPassword = values.requestPassword;
    }
}
