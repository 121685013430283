import { ApiService } from './../services/api.service';
import { ToasterConfig, ToasterService, Toast, BodyOutputType } from "angular2-toaster";
import { DatePipe } from '@angular/common';

export default class Utils {

  static scrollToElement(id: string) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
  }

  static validarDocumento(doc) {

      if (!doc) {
          return false;
      }

      if (Utils.isOnlyNumbers(doc)) {
          if (doc.length == 11) {
              //Verificando números iguais para evitar falsos positivos
              if (Utils.isAllsameNumbers(doc)) {
                  return false;
              }
          } else if (doc.length != 14) {
              return false;
          }

          //Algarismos e DV
          let algarismos: string = doc.substring(0, doc.length - 2);
          let dv: string = doc.substring(doc.length - 2);

          //Obter resto primeiro multiplicador
          let dv2: string = this.obterResto(algarismos);

          //Obter resto segundo multiplicador
          dv2 += this.obterResto(algarismos += dv2);

          return dv == dv2;
      }

      return false;
  }

  private static obterResto(doc) {
      //Multiplicador inicial considerando ser um CPF ou CNPJ
      let multIni: number = doc.length < 12 ? doc.length + 1 : doc.length - 7;

      //Soma elementos multiplicados
      let soma: number = 0;
      for (let i: number = 0; i < doc.length; i++) {
          soma += Number(doc[i].toString()) * multIni;

          //Correção do multiplicador
          multIni = multIni == 2 ? 9 : --multIni;
      }

      //Calculando o resto
      let resto: number = soma % 11;
      resto = resto < 2 ? 0 : 11 - resto;

      //Retorno do dígito
      return resto.toString();
  }

  static formatarDocumento(doc: string,): string {

      if (doc.length < 12) {
          return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4").trim();
      }
      else {
          return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5").trim();
      }
  }

  static showToast(toastConfig: ToasterConfig, type: string, title: string, body: string, toasterService: ToasterService) {
      let position = 'toast-top-right';
      let animationType = 'fade';
      let timeout = 5000;
      let toastsLimit = 5;
      let isNewestOnTop = true;
      let isHideOnClick = true;
      let isDuplicatesPrevented = false;
      let isCloseButton = true;
      toastConfig = new ToasterConfig({
          positionClass: position,
          timeout: timeout,
          newestOnTop: isNewestOnTop,
          tapToDismiss: isHideOnClick,
          preventDuplicates: isDuplicatesPrevented,
          animation: animationType,
          limit: toastsLimit,
      });
      const toast: Toast = {
          type: type,
          title: title,
          body: body,
          timeout: timeout,
          showCloseButton: isCloseButton,
          bodyOutputType: BodyOutputType.TrustedHtml,
      };
      toasterService.popAsync(toast);
  }

  static formatarTelefone(telefone: string): string {
      //9999-9999 - 9dig
      //99999-9999 - 10dig
      //(99) 9999-9999 - 14dig
      //(99) 99999-9999 - 15dig

      telefone = telefone.replace(/[^0-9]+/g, "");

      if (telefone.length <= 9) {
          if (telefone.length == 8) {
              telefone = telefone.substring(0, 4) + "-" + telefone.substring(4);
          }
          else {
              telefone = telefone.substring(0, 5) + "-" + telefone.substring(5, 9);
          }
      }
      else {
          if (telefone.length > 0) {
              telefone = telefone.substring(-1, 0) + "(" + telefone.substring(0);
          }
          if (telefone.length > 3) {
              telefone = telefone.substring(0, 3) + ") " + telefone.substring(3);
          }

          if (telefone.length == 13) {
              telefone = telefone.substring(0, 9) + "-" + telefone.substring(9, 13);
          } else if (telefone.length >= 14) {
              telefone = telefone.substring(0, 10) + "-" + telefone.substring(10, 14);
          }
      }

      return telefone;
  }

  static formatCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }

      return splitStr.join(' ');
  }

  static unmask(val) {
      if (val) {
          return val.replace(/\D+/g, '');
      }

      return '';
  }

  static isOnlyNumbers(texto) {
      return /\d+/g.test(texto);
  }

  static isAllsameNumbers(texto) {
      return /^(\d)\1+$/i.test(texto);
  }

  static GUIDEmpty = '00000000-0000-0000-0000-000000000000';

  static getPastDateInterval(intervalType: any, dtPipe: DatePipe): { start: string; end: string; } {

      let dateIntervalResult = { start: '', end: '' };

      if (intervalType == 0) {
          dateIntervalResult.start = null;
          dateIntervalResult.end = null;
      } else {
          let auxDateIni: Date = new Date();

          if (intervalType == -1) {
              auxDateIni.setDate(1);
          } else if (intervalType == 1) {
              //Só mantem
          } else if (intervalType == 7) {
              auxDateIni.setDate(auxDateIni.getDate() - +intervalType);
          } else if (intervalType == 360) {
              auxDateIni.setDate(1);
              auxDateIni.setMonth(0);
          } else {
              auxDateIni.setMonth(auxDateIni.getMonth() - (+intervalType / 30));
          }

          dateIntervalResult.start = dtPipe.transform(auxDateIni, 'yyyy-MM-dd');
          dateIntervalResult.end = dtPipe.transform(new Date(), 'yyyy-MM-dd');
      }

      return dateIntervalResult;
  }

  static getFutureDateInterval(intervalType: any, dtPipe: DatePipe): { start: string; end: string; } {

      let dateIntervalResult = { start: '', end: '' };

      if (intervalType == 0) {
          dateIntervalResult.start = null;
          dateIntervalResult.end = null;
      } else {
          let auxDateFim: Date = new Date();

          if (intervalType == 7) {
              auxDateFim.setDate(auxDateFim.getDate() + +intervalType);
          } else {
              auxDateFim.setMonth(auxDateFim.getMonth() + (+intervalType / 30));
          }

          dateIntervalResult.start = dtPipe.transform(new Date(), 'yyyy-MM-dd');
          dateIntervalResult.end = dtPipe.transform(auxDateFim, 'yyyy-MM-dd');
      }

      return dateIntervalResult;
  }

  static validaTelaInicial(menu:string, screen: string, employeeId: string): boolean  {
    let valida: boolean = false;
    let api: ApiService;

    const result = api.GetListAccessPermissions(employeeId);
    if (result){
      result.subscribe(dados => {
        if (dados.msgRetorno == "OK"){
          if (dados.accessPermissions.filter(x => x.menu == menu).filter(y => y.screen == screen)[0].id != '00000000-0000-0000-0000-000000000000') {
            valida = true;
          }
        }
      });
    }

    return valida;
  }

  static validaAcento(nome: string): string {
    let nomeSemAcento: string = "";

    if (nome.includes('Á'))
      nomeSemAcento = nome.replace('Á', 'A');

    if (nome.includes('Â'))
      nomeSemAcento = nome.replace('Â', 'A');

    if (nome.includes('À'))
      nomeSemAcento = nome.replace('À', 'A');

    if (nome.includes('Ã'))
      nomeSemAcento = nome.replace('Ã', 'A');

    if (nome.includes('É'))
      nomeSemAcento = nome.replace('É', 'E');

    if (nome.includes('Ê'))
      nomeSemAcento = nome.replace('Ê', 'E');

    if (nome.includes('È'))
      nomeSemAcento = nome.replace('È', 'E');

    if (nome.includes('Í'))
      nomeSemAcento = nome.replace('Í', 'I');

    if (nome.includes('Î'))
      nomeSemAcento = nome.replace('Î', 'I');

    if (nome.includes('Ì'))
      nomeSemAcento = nome.replace('Ì', 'I');

    if (nome.includes('Ó'))
      nomeSemAcento = nome.replace('Ó', 'O');

    if (nome.includes('Ô'))
      nomeSemAcento = nome.replace('Ô', 'O');

    if (nome.includes('Ò'))
      nomeSemAcento = nome.replace('Ò', 'O');

    if (nome.includes('Õ'))
      nomeSemAcento = nome.replace('Õ', 'O');

    if (nome.includes('Ú'))
      nomeSemAcento = nome.replace('Ú', 'U');

    if (nome.includes('Ç'))
      nomeSemAcento = nome.replace('Ç', 'C');

    if (nomeSemAcento == "")
      nomeSemAcento = nome;

    return nomeSemAcento;
  }

  static validarCampoNull(value: any): any {
    if (value == undefined || value == null || value == ''){
      return null;
    }

    return value;
  }

  static validarCampoGuidNull(value: any): any {
    if (value == undefined || value == null || value == ''){
      return '00000000-0000-0000-0000-000000000000';
    }

    return value;
  }

  static validarcampoBoolean(value: any): any{
    let valueReturn = this.validarCampoNull(value);
    if (valueReturn != null){
      if (valueReturn == 'true') {
        return true;
      } else if (valueReturn == 'false'){
        return false;
      }
    }

    return valueReturn;
  }

  static validarCampoNumber(value: any): any {
    let valueReturn = this.validarCampoNull(value);
    if (valueReturn != null || value == undefined || value == "") {
      return Number(valueReturn);
    }

    return 0;
  }

  static validarCampoStr(value: any): any {
    if (value == undefined || value == null){
      return "";
    }

    return value;
  }

  static validarCampoGuid(value: any): any {
    if (value == undefined || value == null || value == ""){
      return "00000000-0000-0000-0000-000000000000";
    }

    return value;
  }

  static successType(): string {
    return 'success';
  }

  static errorType(): string {
    return 'error';
  }

  static infoType(): string {
    return 'info';
  }

  static warningType(): string {
    return 'warning';
  }

  static calcularDiferencaEmDias(data1: Date, data2: Date): number {
    const diffEmMilissegundos = data2.getTime() - data1.getTime();
    const diffEmDias = diffEmMilissegundos / (1000 * 60 * 60 * 24);
    return Math.floor(diffEmDias);
  }

  static omitKeys(obj, keys) {
    var dup = {};
    for (var key in obj) {
      if (keys.indexOf(key) == -1) {
        dup[key] = obj[key];
      }
    }
    return dup;
  }


}
