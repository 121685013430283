export class ProcessoTickerSymbol {
  retorno: string;
  retornoEmail: string;

  constructor(values: { retorno: string, retornoEmail: string}) {

      this.retorno  = values.retorno;
      this.retornoEmail = values.retornoEmail;
  }
}
