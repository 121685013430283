import { ApiNotificacao } from "./api-notificacao";
import { PagamentoColaborador } from "./pagamentocolaborador";

export class PagamentoColaboradorResult {
    success: boolean;
    data: PagamentoColaborador;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}