import { ClipboardModule } from 'ngx-clipboard';
/**
 * @license
 * Copyright WSSoft. All Rights Reserved.
 * Licensed under the Proprietary License.
 */
import { APP_BASE_HREF, Location } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ConnectionBackend, HttpModule, RequestOptions, XHRBackend } from '@angular/http';
import { CoreModule } from './@core/core.module';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './auth-guard.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NbAuthModule, NB_AUTH_TOKEN_CLASS, NbAuthJWTToken } from '@nebular/auth';
import { NbEmailPassAuthProvider } from './@theme/components/auth/providers/email-pass-auth.provider';
import { NegocioDataService } from './services/negocio-data.service';
import { HttpCustomClient } from './services/httpcustom';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SelectModule } from 'ng-select';
import { NbAuthComponent } from './@theme/components/auth/auth.component';
import { NbAuthBlockComponent } from './@theme/components/auth/auth-block/auth-block.component';
import { NbLoginComponent } from './@theme/components/auth/login/login.component';
import { NbRegisterComponent } from './@theme/components/auth/register/register.component';
import { NbRequestPasswordComponent } from './@theme/components/auth/request-password/request-password.component';
import { NbResetPasswordComponent } from './@theme/components/auth/reset-password/reset-password.component';
import { NbLogoutComponent } from './@theme/components/auth/logout/logout.component';
import { environment } from '../environments/environment';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './shared/router/custom-reuse-strategy';
import { ApiService } from './services/api.service';
import { NbCarregaUsuarioEmailComponent } from './@theme/components/auth/carrega-usuario-email/carrega-usuario-email.component';
import { CadastrarSenhaComponent } from './@theme/components/auth/cadastrar-senha/cadastrar-senha.component';
import { QuillModule } from 'ngx-quill';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

const API_URL = environment.apiUrl;

const formSetting: any = {
  redirectDelay: 0,
  showMessages: {
    success: true,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NbAuthComponent,
    NbAuthBlockComponent,
    NbLoginComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
    NbLogoutComponent,
    NbCarregaUsuarioEmailComponent,
    CadastrarSenhaComponent,
  ],
  exports: [
    NbAuthComponent,
    NbAuthBlockComponent,
    NbLoginComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
    NbLogoutComponent,
    NbCarregaUsuarioEmailComponent,
    CadastrarSenhaComponent,
    QuillModule,
    MatTabsModule,
    ClipboardModule,
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    Ng2SmartTableModule,
    QuillModule.forRoot(),
    FormsModule,
    SelectModule,
    MatTabsModule,
    MatInputModule,
    ClipboardModule,
    NgbTooltipModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbAuthModule.forRoot({
      providers: {
        email: {
          service: NbEmailPassAuthProvider,
          config: {
            baseEndpoint: API_URL,
            login: {
              endpoint: '/auth/sign-in',
              method: 'post',
            },
            logout: {
              endpoint: '/auth/sign-out',
              method: 'post',
            },
            token: {
              key: 'token',
            },
          },
        },
      },
      forms: {
        login: formSetting,
        register: formSetting,
        requestPassword: formSetting,
        resetPassword: formSetting,
        logout: {
          redirectDelay: 0,
        },
      },
    }),

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NB_AUTH_TOKEN_CLASS, useValue: NbAuthJWTToken },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: HttpCustomClient,
      useFactory: (backend: XHRBackend, options: RequestOptions, api: ApiService) => {
        return new HttpCustomClient(backend, options, api);
      },
      deps: [XHRBackend, RequestOptions, ApiService]
    },
    AuthGuard,
    NegocioDataService,
    NbEmailPassAuthProvider,
    Location
  ],
})
export class AppModule { }
