import { User } from './../../../../shared/user';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { NegocioDataService } from '../../../../services/negocio-data.service';
import Utils from '../../../../shared/utils';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { environment } from '../../../../../environments/environment';
import { CustomReuseStrategy } from '../../../../shared/router/custom-reuse-strategy';

const LOGN = environment.login;
const PSWD = environment.password;

@Component({
  selector: 'app-cadastrar-senha',
  templateUrl: './cadastrar-senha.component.html',
  styleUrls: ['./cadastrar-senha.component.css']
})
export class CadastrarSenhaComponent implements OnInit {

  get senha(): string {
    return this.route.snapshot.paramMap.get('senha');
  }

  configU: ToasterConfig;
  user: any = {};
  userToken: any = {};
  submitted = false;
  provider: string = '';
  messages: string[] = [];
  errors: string[] = [];
  redirectDelay: number = 0;
  successType = 'success';
  errorType = 'error';
  infoType = 'info';
  warningType = 'warning';
  updateInProgress = false;
  editNewPassord = true;
  hide = true;
  hide2 = true;

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected config = {},
    private negocioDataService: NegocioDataService,
    private route: ActivatedRoute,
    private router: Router,
    private routeReuse: RouteReuseStrategy

    ) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.provider = this.getConfigValue('forms.login.provider');
  }

  newUser: User = new User ({
    id: '',
    username: '',
    password: '',
    active: 'false',
    document: '',
    name: '',
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    email: '',
    created_date: '',
    changed_date: '',
    employeeLog_id: '',
    changePassword: '',
    msg: '',
    responsibleAnalyst: '',
    id_employeeType: '',
    requestPassword: ''
  });

  ngOnInit() {
    // this.loggedEmployee();
  }

  login(): void {
    if (this.user.password == this.user.confirmPassword)
    {
      this.newUser.username = '';
      this.newUser.active = 'false';
      this.newUser.document = '';
      this.newUser.firstName = '';
      this.newUser.middleName = '';
      this.newUser.lastName = '';
      this.newUser.birthDate = '';
      this.newUser.created_date = '';
      this.newUser.changed_date = '';
      this.newUser.password = this.user.password;
      this.newUser.employeeLog_id = this.newUser.id;

      const result = this.negocioDataService.getAtualizarSenha(this.newUser);
      if (result) {
        result.subscribe(dados => {
          if (dados.msg == "OK"){
            this.loginToken();
          }
        })
      }
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

  loggedEmployee() {
    this.userToken.email = LOGN;
    this.userToken.password = PSWD;

    this.service.authenticate(this.provider, this.userToken).subscribe((result: NbAuthResult) => {

       if (result.isSuccess()) {
         this.messages = result.getMessages();
         this.updateInProgress = true;
       } else {
         this.errors = result.getErrors();
       }
    });

    const result = this.negocioDataService.getValidaSenha(this.senha);

    if (result){
      result.subscribe(dados => {
        if (dados.id == '00000000-0000-0000-0000-000000000000')
          this.router.navigate(['auth/login']);
        else{
          this.newUser = dados;
          this.user.nome = 'Olá ' + dados.name;
          this.editNewPassord = false;
          this.logout();
        }
      });
    }
  }

  loginToken(): void {
    this.updateInProgress = false;
    this.userToken.email = this.newUser.email;
    this.userToken.password = this.newUser.password;

    this.service.authenticate(this.provider, this.userToken).subscribe((result: NbAuthResult) => {

       if (result.isSuccess()) {
        this.router.navigate(['pages/clientes/lista']);
       } else {
         this.errors = result.getErrors();
       }
    });
  }

  podeCadastrar() {
    return !this.updateInProgress;
  }

  podePreencher() {
    return !this.editNewPassord;
  }

  logout(): void {
    this.service.logout(this.provider).subscribe((result: NbAuthResult) => {
      (<CustomReuseStrategy>this.routeReuse).clearHandlers();
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          //return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }
}
