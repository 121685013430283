import { DealBusinessSituationCommandResult } from "./DealBusinessSituationCommandResult";
import { GetAccessPermissionsCommandResult } from "./GetAccessPermissionsCommandResult";
import { RankingListCommandResult } from "./RankingListCommandResult";
import { User } from "./user";

export class MensagemCommandResult {
  msgErro: string;
  msgRetorno: string;
  validate: boolean;
  ranking: RankingListCommandResult;
  valida: boolean;
  employee: EmployeeReturn;
  dealDeleteBase: boolean;
  user: User;
  result: any;
  accessPermissions: GetAccessPermissionsCommandResult;
  resultList: any[] = [];

  constructor(values: {
    msgErro: string,
    msgRetorno: string,
    validate: boolean,
    ranking: RankingListCommandResult,
    valida: boolean,
    employee: EmployeeReturn,
    dealDeleteBase: boolean,
    user: User,
    result: any,
    accessPermissions: GetAccessPermissionsCommandResult,
    resultList: any[]
  }){
    this.msgErro = values.msgErro;
    this.msgRetorno = values.msgRetorno;
    this.validate = values.validate;
    this.ranking = values.ranking;
    this.valida = values.valida;
    this.employee = values.employee;
    this.dealDeleteBase = values.dealDeleteBase;
    this.user = values.user;
    this.result = values.result;
    this.accessPermissions = values.accessPermissions;
    this.resultList = values.resultList;
  }
}

export class EmployeeReturn {
  resetSenha: boolean;

  constructor(values: {
    resetSenha: boolean
  }){
    this.resetSenha = values.resetSenha;
  }
}
