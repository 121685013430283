import { DatePipe } from "@angular/common";
export class EmployeeCommandResult {
  id: string;
  employeeName: string;
  totalNegocio: number;

  constructor(values:
  {
    id: string,
    employeeName: string,
    totalNegocio: number
  }) {
      this.id = values.id;
      this.employeeName = values.employeeName;
      this.totalNegocio = values.totalNegocio;
    }
}
