export class CreateUser {
  forEach(arg0: (value: any) => void) {
    throw new Error('Method not implemented.');
  }

    id: string;
    deal: string;
    document: string;
    name: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    type: string;
    typeDescription: string;
    birthDate: string;
    active: boolean;
    typeAction: string;
    msg: string;
    msgEmail: string;
    EmployeeLog_id: string;
    responsibleAnalyst: boolean;
    id_employeeType: string;

    constructor(values: Object = {}) {
      Object.assign(this, values);
  }
}
