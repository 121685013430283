export class AccessMenu {
    id: string;
    menu: string;

    constructor(values: { id: string, menu: string}) {

        this.id = values.id;
        this.menu = values.menu;
    }
}
