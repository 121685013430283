export class Papel {

    private _id: string;    
    get id(): string {
        return this._id;
    }
    set id(newId: string) {
        this._id = newId;
    }

    private _code: string;
    get code(): string {
        return this._code;
    }
    set code(newCode: string) {
        this._code = newCode;
    }

    private _price: number;
    get price(): number {
        return this._price;
    }
    set price(newPrice: number) {
        this._price = newPrice;
    }
    
    constructor(values: { id: string, code: string, price: number }) {
        this.id = values.id;
        this.code = values.code;
        this.price = values.price;
    }
}
