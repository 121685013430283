import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NegocioDataService } from '../../../../services/negocio-data.service';
import { UserBirthday } from '../../../../shared/BirthdaysCommandResult';

@Component({
  selector: 'modal-birthday',
  templateUrl: './modal-birthday.component.html',
  styleUrls: ['./modal-birthday.component.scss']
})
export class ModalBirthdayComponent implements OnInit {
  dia:number = new Date().getDate();
  mes:number = new Date().getMonth() + 1;

  janeiro: UserBirthday[] = [];
  fevereiro: UserBirthday[] = [];
  marco: UserBirthday[] = [];
  abril: UserBirthday[] = [];
  maio: UserBirthday[] = [];
  junho: UserBirthday[] = [];
  julho: UserBirthday[] = [];
  agosto: UserBirthday[] = [];
  setembro: UserBirthday[] = [];
  outubro: UserBirthday[] = [];
  novembro: UserBirthday[] = [];
  dezembro: UserBirthday[] = [];

  constructor(private activeModal: NgbActiveModal,
              private negocioDataService: NegocioDataService) { }

  ngOnInit() {
    this.carregarAniversariantes();
  }

  fecharPopup() {
    this.activeModal.close();
  }

  carregarAniversariantes(){
    const result = this.negocioDataService.GetBirthdays();

    if (result)
    {
      result.subscribe(dados => {
        if (dados.msgRetorno == "OK"){
          this.janeiro = dados.birthdays.janeiro;
          this.fevereiro = dados.birthdays.fevereiro;
          this.marco = dados.birthdays.marco;
          this.abril = dados.birthdays.abril;
          this.maio = dados.birthdays.maio;
          this.junho = dados.birthdays.junho;
          this.julho = dados.birthdays.julho;
          this.agosto = dados.birthdays.agosto;
          this.setembro = dados.birthdays.setembro;
          this.outubro = dados.birthdays.outubro;
          this.novembro = dados.birthdays.novembro;
          this.dezembro = dados.birthdays.dezembro;
        }
      });
    }
  }


}
