import { ApiNotificacao } from "./api-notificacao";
import { DefaultData } from "./default-data";

export class DefaultResult {
    success: boolean;
    data: DefaultData;
    notifications: ApiNotificacao[];
    errors: string[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}